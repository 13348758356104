/* eslint-disable */
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date */
  Date: any;
  /** DateTime */
  DateTime: any;
  /** Raw JSON value */
  Json: any;
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
};

export type AnnouncementBanner = Document & Linkable & {
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_url?: Maybe<Linkable>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type AnnouncementBannerConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<AnnouncementBannerConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type AnnouncementBannerConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: AnnouncementBanner;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type Author = Document & Linkable & {
  readonly honorific_prefix?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly honorific_suffix?: Maybe<Scalars['String']>;
  readonly bio?: Maybe<Scalars['String']>;
  readonly author_page?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly same_as_links?: Maybe<ReadonlyArray<AuthorSameAsLinks>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type AuthorConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<AuthorConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type AuthorConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: Author;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type AuthorSameAsLinks = {
  readonly same_as_link?: Maybe<Linkable>;
};

export type Capability = Document & Linkable & {
  readonly parent_id?: Maybe<Linkable>;
  readonly body?: Maybe<ReadonlyArray<CapabilityBody>>;
  readonly body1?: Maybe<ReadonlyArray<CapabilityBody1>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type CapabilityBody = CapabilityBodyThreeSimpleCards | CapabilityBodyTextAndImage | CapabilityBodyCapabilityList | CapabilityBodyTable | CapabilityBodyFullWidthCta | CapabilityBodyNavigationTitle | CapabilityBodyImageAndList | CapabilityBodyNestedContent | CapabilityBodyRichText | CapabilityBodyContentPost | CapabilityBodyImageCarousel | CapabilityBodyResourcesPosts | CapabilityBodyNewsletterSignup | CapabilityBodyCenteredFullWidthCta | CapabilityBodyYoutubeVideo | CapabilityBodyMediaMarquee | CapabilityBodyLinkGrid | CapabilityBodyImageGrid | CapabilityBodyInstantQuoteHero | CapabilityBodyCenteredHero | CapabilityBodyAdvancedTable | CapabilityBodyNearbyLocations | CapabilityBodyImageAndCaption | CapabilityBodyQueriedResourcePosts | CapabilityBodyBenefitQuadrant | CapabilityBodyCarbonOffset | CapabilityBodyFeaturedCustomers | CapabilityBodyPullQuote | CapabilityBodyXometryEverywhere | CapabilityBodyThreeSimpleCircles | CapabilityBodyInlineCta | CapabilityBodyTestimonials | CapabilityBodyTestimonial | CapabilityBodySimpleInlineCta | CapabilityBodyCountUp | CapabilityBodyTextAndModelFile | CapabilityBodyQuoteUploadComponent | CapabilityBodyLatestCapabilities | CapabilityBodyVideoCta | CapabilityBodyImageCta | CapabilityBodyTextAndImageRows | CapabilityBodyReusableCta | CapabilityBodyRepeatableRichText | CapabilityBodyTextAndImageV2 | CapabilityBodySectionDivider | CapabilityBodyAdvancedSlice | CapabilityBodyLandingPageHero | CapabilityBodyHeroAuthor;

export type CapabilityBody1 = CapabilityBody1Seo | CapabilityBody1PageLocales;

export type CapabilityBody1PageLocales = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBody1PageLocalesFields>>;
};

export type CapabilityBody1PageLocalesFields = {
  readonly locale?: Maybe<Scalars['String']>;
  readonly page?: Maybe<Linkable>;
};

export type CapabilityBody1Seo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBody1SeoPrimary>;
};

export type CapabilityBody1SeoPrimary = {
  readonly meta_title?: Maybe<Scalars['String']>;
  readonly meta_description?: Maybe<Scalars['String']>;
};

export type CapabilityBodyAdvancedSlice = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyAdvancedSlicePrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyAdvancedSliceFields>>;
};

export type CapabilityBodyAdvancedSliceFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
  readonly content_post?: Maybe<Linkable>;
};

export type CapabilityBodyAdvancedSlicePrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly text_and_image_alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly use_image_as_background?: Maybe<Scalars['Boolean']>;
  readonly image_background_alignment?: Maybe<Scalars['String']>;
  readonly repeatable_layout?: Maybe<Scalars['String']>;
};

export type CapabilityBodyAdvancedTable = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyAdvancedTablePrimary>;
};

export type CapabilityBodyAdvancedTablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption?: Maybe<Scalars['Json']>;
  readonly csv_file?: Maybe<Linkable>;
};

export type CapabilityBodyBenefitQuadrant = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyBenefitQuadrantPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyBenefitQuadrantFields>>;
};

export type CapabilityBodyBenefitQuadrantFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyBenefitQuadrantPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['Json']>;
  readonly footer?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyCapabilityList = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyCapabilityListPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyCapabilityListFields>>;
};

export type CapabilityBodyCapabilityListFields = {
  readonly capability?: Maybe<Linkable>;
};

export type CapabilityBodyCapabilityListPrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type CapabilityBodyCarbonOffset = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyCarbonOffsetPrimary>;
};

export type CapabilityBodyCarbonOffsetPrimary = {
  readonly header?: Maybe<Scalars['Json']>;
  readonly text?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyCenteredFullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyCenteredFullWidthCtaPrimary>;
};

export type CapabilityBodyCenteredFullWidthCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
};

export type CapabilityBodyCenteredHero = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyCenteredHeroPrimary>;
};

export type CapabilityBodyCenteredHeroPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_microcopy?: Maybe<Scalars['Boolean']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyContentPost = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyContentPostPrimary>;
};

export type CapabilityBodyContentPostPrimary = {
  readonly content_post?: Maybe<Linkable>;
  readonly content_icon?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyCountUp = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyCountUpPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyCountUpFields>>;
};

export type CapabilityBodyCountUpFields = {
  readonly category_image?: Maybe<Scalars['Json']>;
  readonly category_title?: Maybe<Scalars['String']>;
  readonly number?: Maybe<Scalars['Float']>;
  readonly suffix?: Maybe<Scalars['String']>;
};

export type CapabilityBodyCountUpPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly count_up_subtitle?: Maybe<Scalars['String']>;
};

export type CapabilityBodyFeaturedCustomers = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyFeaturedCustomersPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyFeaturedCustomersFields>>;
};

export type CapabilityBodyFeaturedCustomersFields = {
  readonly company_logo?: Maybe<Scalars['Json']>;
  readonly company_link?: Maybe<Linkable>;
};

export type CapabilityBodyFeaturedCustomersPrimary = {
  readonly subtitle?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyFullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyFullWidthCtaPrimary>;
};

export type CapabilityBodyFullWidthCtaPrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly fine_print?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyHeroAuthor = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyHeroAuthorPrimary>;
};

export type CapabilityBodyHeroAuthorPrimary = {
  readonly author?: Maybe<Linkable>;
};

export type CapabilityBodyImageAndList = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyImageAndListPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyImageAndListFields>>;
};

export type CapabilityBodyImageAndListFields = {
  readonly image1?: Maybe<Scalars['Json']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyImageAndListPrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type CapabilityBodyImageAndCaption = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyImageAndCaptionPrimary>;
};

export type CapabilityBodyImageAndCaptionPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly caption?: Maybe<Scalars['String']>;
};

export type CapabilityBodyImageCarousel = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyImageCarouselPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyImageCarouselFields>>;
};

export type CapabilityBodyImageCarouselFields = {
  readonly image1?: Maybe<Scalars['Json']>;
  readonly image_caption?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyImageCarouselPrimary = {
  readonly primary_caption?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyImageCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyImageCtaPrimary>;
};

export type CapabilityBodyImageCtaPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyImageGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyImageGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyImageGridFields>>;
};

export type CapabilityBodyImageGridFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyImageGridPrimary = {
  readonly columns?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type CapabilityBodyInlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyInlineCtaPrimary>;
};

export type CapabilityBodyInlineCtaPrimary = {
  readonly copy?: Maybe<Scalars['String']>;
  readonly cta_text1?: Maybe<Scalars['String']>;
  readonly cta_link1?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyInstantQuoteHero = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyInstantQuoteHeroPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyInstantQuoteHeroFields>>;
};

export type CapabilityBodyInstantQuoteHeroFields = {
  readonly cross_link_text?: Maybe<Scalars['String']>;
  readonly cross_link?: Maybe<Linkable>;
};

export type CapabilityBodyInstantQuoteHeroPrimary = {
  readonly capability_title?: Maybe<Scalars['Json']>;
  readonly capability_subtitle?: Maybe<Scalars['Json']>;
  readonly quote_cta_title?: Maybe<Scalars['Json']>;
  readonly quote_cta_subtitle?: Maybe<Scalars['Json']>;
  readonly quote_cta_text?: Maybe<Scalars['String']>;
  readonly quote_cta_link?: Maybe<Linkable>;
  readonly video_cta_text?: Maybe<Scalars['String']>;
  readonly video_cta_link?: Maybe<Linkable>;
  readonly page_image?: Maybe<Scalars['Json']>;
  readonly quote_image_left?: Maybe<Scalars['Json']>;
  readonly quote_image_center?: Maybe<Scalars['Json']>;
  readonly quote_image_right?: Maybe<Scalars['Json']>;
  readonly cross_links_title?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyLandingPageHero = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyLandingPageHeroPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyLandingPageHeroFields>>;
};

export type CapabilityBodyLandingPageHeroFields = {
  readonly quick_fact_text?: Maybe<Scalars['Json']>;
  readonly quick_fact_icon?: Maybe<Scalars['String']>;
};

export type CapabilityBodyLandingPageHeroPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_microcopy?: Maybe<Scalars['Boolean']>;
  readonly rfq_cta_type?: Maybe<Scalars['String']>;
  readonly rfq_cta_section_title?: Maybe<Scalars['String']>;
  readonly customer_rfq_prompt_text?: Maybe<Scalars['String']>;
  readonly customer_rfq_prompt_button_text?: Maybe<Scalars['String']>;
};

export type CapabilityBodyLatestCapabilities = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyLatestCapabilitiesPrimary>;
};

export type CapabilityBodyLatestCapabilitiesPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly publication_date_sort_order?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly number_of_post?: Maybe<Scalars['String']>;
};

export type CapabilityBodyLinkGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyLinkGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyLinkGridFields>>;
};

export type CapabilityBodyLinkGridFields = {
  readonly link_text?: Maybe<Scalars['String']>;
  readonly link?: Maybe<Linkable>;
};

export type CapabilityBodyLinkGridPrimary = {
  readonly link_grid_title?: Maybe<Scalars['String']>;
};

export type CapabilityBodyMediaMarquee = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyMediaMarqueePrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyMediaMarqueeFields>>;
};

export type CapabilityBodyMediaMarqueeFields = {
  readonly feature_image?: Maybe<Scalars['Json']>;
  readonly feature_title?: Maybe<Scalars['String']>;
  readonly feature_description?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyMediaMarqueePrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly media_marquee_description?: Maybe<Scalars['Json']>;
  readonly video_url?: Maybe<Scalars['Json']>;
  readonly footer?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyNavigationTitle = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyNavigationTitlePrimary>;
};

export type CapabilityBodyNavigationTitlePrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type CapabilityBodyNearbyLocations = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyNearbyLocationsPrimary>;
};

export type CapabilityBodyNearbyLocationsPrimary = {
  readonly locations_title?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyNestedContent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyNestedContentPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyNestedContentFields>>;
};

export type CapabilityBodyNestedContentFields = {
  readonly header?: Maybe<Scalars['String']>;
  readonly content?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyNestedContentPrimary = {
  readonly width?: Maybe<Scalars['String']>;
  readonly is_faq?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly title_alignment?: Maybe<Scalars['String']>;
  readonly expand_first_item?: Maybe<Scalars['Boolean']>;
  readonly content_layout?: Maybe<Scalars['String']>;
};

export type CapabilityBodyNewsletterSignup = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyNewsletterSignupPrimary>;
};

export type CapabilityBodyNewsletterSignupPrimary = {
  readonly description?: Maybe<Scalars['String']>;
  readonly cta_button_text?: Maybe<Scalars['String']>;
};

export type CapabilityBodyPullQuote = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyPullQuotePrimary>;
};

export type CapabilityBodyPullQuotePrimary = {
  readonly quote?: Maybe<Scalars['String']>;
  readonly attribution_name?: Maybe<Scalars['String']>;
  readonly attribution_title?: Maybe<Scalars['String']>;
};

export type CapabilityBodyQueriedResourcePosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyQueriedResourcePostsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyQueriedResourcePostsFields>>;
};

export type CapabilityBodyQueriedResourcePostsFields = {
  readonly content_category_filter?: Maybe<Linkable>;
  readonly author_filter?: Maybe<Linkable>;
  readonly hide_author_name?: Maybe<Scalars['Boolean']>;
};

export type CapabilityBodyQueriedResourcePostsPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly publication_date_sort_order?: Maybe<Scalars['String']>;
  readonly number_of_posts?: Maybe<Scalars['String']>;
  readonly content_type_filter?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
};

export type CapabilityBodyQuoteUploadComponent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyQuoteUploadComponentPrimary>;
};

export type CapabilityBodyQuoteUploadComponentPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly upload_component_heading?: Maybe<Scalars['String']>;
  readonly upload_component_cta?: Maybe<Scalars['String']>;
};

export type CapabilityBodyRepeatableRichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyRepeatableRichTextPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyRepeatableRichTextFields>>;
};

export type CapabilityBodyRepeatableRichTextFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyRepeatableRichTextPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyResourcesPosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyResourcesPostsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyResourcesPostsFields>>;
};

export type CapabilityBodyResourcesPostsFields = {
  readonly content_post?: Maybe<Linkable>;
};

export type CapabilityBodyResourcesPostsPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly button_text?: Maybe<Scalars['String']>;
};

export type CapabilityBodyReusableCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyReusableCtaPrimary>;
};

export type CapabilityBodyReusableCtaPrimary = {
  readonly link_to_document?: Maybe<Linkable>;
};

export type CapabilityBodyRichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyRichTextPrimary>;
};

export type CapabilityBodyRichTextPrimary = {
  readonly highlight_section?: Maybe<Scalars['Boolean']>;
  readonly title1?: Maybe<Scalars['String']>;
  readonly rich_text?: Maybe<Scalars['Json']>;
};

export type CapabilityBodySectionDivider = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodySectionDividerPrimary>;
};

export type CapabilityBodySectionDividerPrimary = {
  readonly transition?: Maybe<Scalars['String']>;
  readonly illustration?: Maybe<Scalars['Json']>;
};

export type CapabilityBodySimpleInlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodySimpleInlineCtaPrimary>;
};

export type CapabilityBodySimpleInlineCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
};

export type CapabilityBodyTable = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyTablePrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyTableFields>>;
};

export type CapabilityBodyTableFields = {
  readonly column_1_entry?: Maybe<Scalars['Json']>;
  readonly column_2_entry?: Maybe<Scalars['Json']>;
  readonly column_3_entry?: Maybe<Scalars['Json']>;
  readonly column_4_entry?: Maybe<Scalars['Json']>;
  readonly column_5_entry?: Maybe<Scalars['Json']>;
  readonly column_6_entry?: Maybe<Scalars['Json']>;
  readonly column_7_entry?: Maybe<Scalars['Json']>;
  readonly column_8_entry?: Maybe<Scalars['Json']>;
  readonly column_9_entry?: Maybe<Scalars['Json']>;
  readonly column_10_entry?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyTablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption_rich_text?: Maybe<Scalars['Json']>;
  readonly column_1_header?: Maybe<Scalars['String']>;
  readonly column_2_header?: Maybe<Scalars['String']>;
  readonly column_3_header?: Maybe<Scalars['String']>;
  readonly column_4_header?: Maybe<Scalars['String']>;
  readonly column_5_header?: Maybe<Scalars['String']>;
  readonly column_6_header?: Maybe<Scalars['String']>;
  readonly column_7_header?: Maybe<Scalars['String']>;
  readonly column_8_header?: Maybe<Scalars['String']>;
  readonly column_9_header?: Maybe<Scalars['String']>;
  readonly column_10_header?: Maybe<Scalars['String']>;
};

export type CapabilityBodyTestimonial = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyTestimonialPrimary>;
};

export type CapabilityBodyTestimonialPrimary = {
  readonly quote?: Maybe<Scalars['String']>;
  readonly person_name?: Maybe<Scalars['String']>;
  readonly person_title?: Maybe<Scalars['String']>;
  readonly person_image?: Maybe<Scalars['Json']>;
  readonly company_image?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyTestimonials = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyTestimonialsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyTestimonialsFields>>;
};

export type CapabilityBodyTestimonialsFields = {
  readonly person_image?: Maybe<Scalars['Json']>;
  readonly company_image?: Maybe<Scalars['Json']>;
  readonly quote?: Maybe<Scalars['String']>;
  readonly person_name?: Maybe<Scalars['String']>;
  readonly person_title?: Maybe<Scalars['String']>;
};

export type CapabilityBodyTestimonialsPrimary = {
  readonly title?: Maybe<Scalars['String']>;
};

export type CapabilityBodyTextAndImage = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyTextAndImagePrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyTextAndImageFields>>;
};

export type CapabilityBodyTextAndImageFields = {
  readonly bullet_point?: Maybe<Scalars['String']>;
};

export type CapabilityBodyTextAndImagePrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly display_new_badge?: Maybe<Scalars['Boolean']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyTextAndImageRows = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyTextAndImageRowsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyTextAndImageRowsFields>>;
};

export type CapabilityBodyTextAndImageRowsFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyTextAndImageRowsPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyTextAndImageV2 = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyTextAndImageV2Primary>;
};

export type CapabilityBodyTextAndImageV2Primary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly use_image_as_background?: Maybe<Scalars['Boolean']>;
  readonly image_background_alignment?: Maybe<Scalars['String']>;
};

export type CapabilityBodyTextAndModelFile = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyTextAndModelFilePrimary>;
  readonly fields?: Maybe<ReadonlyArray<CapabilityBodyTextAndModelFileFields>>;
};

export type CapabilityBodyTextAndModelFileFields = {
  readonly bullet_point?: Maybe<Scalars['String']>;
};

export type CapabilityBodyTextAndModelFilePrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly display_new_badge?: Maybe<Scalars['Boolean']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly model_file?: Maybe<Linkable>;
  readonly cta_text?: Maybe<Scalars['String']>;
};

export type CapabilityBodyThreeSimpleCards = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyThreeSimpleCardsPrimary>;
};

export type CapabilityBodyThreeSimpleCardsPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly card_1_image?: Maybe<Scalars['Json']>;
  readonly card_1_title?: Maybe<Scalars['String']>;
  readonly card_1_description?: Maybe<Scalars['Json']>;
  readonly card_2_image?: Maybe<Scalars['Json']>;
  readonly card_2_title?: Maybe<Scalars['String']>;
  readonly card_2_description?: Maybe<Scalars['Json']>;
  readonly card_3_image?: Maybe<Scalars['Json']>;
  readonly card_3_title?: Maybe<Scalars['String']>;
  readonly card_3_description?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyThreeSimpleCircles = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyThreeSimpleCirclesPrimary>;
};

export type CapabilityBodyThreeSimpleCirclesPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly circle_1_image?: Maybe<Scalars['Json']>;
  readonly circle_1_title?: Maybe<Scalars['String']>;
  readonly circle_1_description?: Maybe<Scalars['Json']>;
  readonly circle_2_image?: Maybe<Scalars['Json']>;
  readonly circle_2_title?: Maybe<Scalars['String']>;
  readonly circle_2_description?: Maybe<Scalars['Json']>;
  readonly circle_3_image?: Maybe<Scalars['Json']>;
  readonly circle_3_title?: Maybe<Scalars['String']>;
  readonly circle_3_description?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyVideoCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyVideoCtaPrimary>;
};

export type CapabilityBodyVideoCtaPrimary = {
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly video_url?: Maybe<Scalars['Json']>;
};

export type CapabilityBodyXometryEverywhere = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
};

export type CapabilityBodyYoutubeVideo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<CapabilityBodyYoutubeVideoPrimary>;
};

export type CapabilityBodyYoutubeVideoPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['Json']>;
};

/** A connection to a list of items. */
export type CapabilityConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<CapabilityConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type CapabilityConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: Capability;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type ContentCategory = Document & Linkable & {
  readonly category_title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
  readonly body?: Maybe<ReadonlyArray<ContentCategoryBody>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type ContentCategoryBody = ContentCategoryBodySeo | ContentCategoryBodyPageLocales;

export type ContentCategoryBodyPageLocales = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<ContentCategoryBodyPageLocalesFields>>;
};

export type ContentCategoryBodyPageLocalesFields = {
  readonly locale?: Maybe<Scalars['String']>;
  readonly page?: Maybe<Linkable>;
};

export type ContentCategoryBodySeo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentCategoryBodySeoPrimary>;
};

export type ContentCategoryBodySeoPrimary = {
  readonly meta_title?: Maybe<Scalars['String']>;
  readonly meta_description?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type ContentCategoryConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<ContentCategoryConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type ContentCategoryConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: ContentCategory;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type ContentHub = Document & Linkable & {
  readonly content_hub_name?: Maybe<Scalars['String']>;
  readonly content_hub_description?: Maybe<Scalars['Json']>;
  readonly content_hub_image?: Maybe<Scalars['Json']>;
  readonly large_featured_post?: Maybe<Linkable>;
  readonly small_featured_posts?: Maybe<ReadonlyArray<ContentHubSmallFeaturedPosts>>;
  readonly body?: Maybe<ReadonlyArray<ContentHubBody>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type ContentHubBody = ContentHubBodySeo | ContentHubBodyPageLocales;

export type ContentHubBodyPageLocales = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<ContentHubBodyPageLocalesFields>>;
};

export type ContentHubBodyPageLocalesFields = {
  readonly locale?: Maybe<Scalars['String']>;
  readonly page?: Maybe<Linkable>;
};

export type ContentHubBodySeo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentHubBodySeoPrimary>;
};

export type ContentHubBodySeoPrimary = {
  readonly meta_title?: Maybe<Scalars['String']>;
  readonly meta_description?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type ContentHubConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<ContentHubConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type ContentHubConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: ContentHub;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type ContentHubSmallFeaturedPosts = {
  readonly post?: Maybe<Linkable>;
};

export type ContentPost = Document & Linkable & {
  readonly publication_date_override?: Maybe<Scalars['Date']>;
  readonly content_type?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Linkable>;
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly author?: Maybe<Linkable>;
  readonly editor?: Maybe<Linkable>;
  readonly form_title?: Maybe<Scalars['String']>;
  readonly form_subtitle?: Maybe<Scalars['Json']>;
  readonly hubspot_form_id?: Maybe<Scalars['String']>;
  readonly body1?: Maybe<ReadonlyArray<ContentPostBody1>>;
  readonly body?: Maybe<ReadonlyArray<ContentPostBody>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type ContentPostBody = ContentPostBodySeo | ContentPostBodyPageLocales;

export type ContentPostBody1 = ContentPostBody1RichText | ContentPostBody1PullQuote | ContentPostBody1ImageCarousel | ContentPostBody1InlineCta | ContentPostBody1YoutubeVideo | ContentPostBody1CenteredFullWidthCta | ContentPostBody1Table | ContentPostBody1ImageGrid | ContentPostBody1XometryEverywhere | ContentPostBody1AdvancedTable | ContentPostBody1ImageAndCaption | ContentPostBody1BenefitQuadrant | ContentPostBody1CapabilityList | ContentPostBody1CarbonOffset | ContentPostBody1FullWidthCta | ContentPostBody1FeaturedCustomers | ContentPostBody1CountUp | ContentPostBody1ContentPost | ContentPostBody1NewsletterSignup | ContentPostBody1NestedContent | ContentPostBody1ImageAndList | ContentPostBody1MediaMarquee | ContentPostBody1LinkGrid | ContentPostBody1ThreeSimpleCards | ContentPostBody1ThreeSimpleCircles | ContentPostBody1TextAndImage | ContentPostBody1Testimonials | ContentPostBody1Testimonial | ContentPostBody1SimpleInlineCta | ContentPostBody1QueriedResourcePosts | ContentPostBody1ResourcesPosts | ContentPostBody1TextAndModelFile | ContentPostBody1QuoteUploadComponent | ContentPostBody1LatestCapabilities | ContentPostBody1VideoCta | ContentPostBody1ImageCta | ContentPostBody1TextAndImageRows | ContentPostBody1ReusableCta | ContentPostBody1RepeatableRichText | ContentPostBody1TextAndImageV2 | ContentPostBody1SectionDivider | ContentPostBody1AdvancedSlice | ContentPostBody1HeroAuthor;

export type ContentPostBody1AdvancedSlice = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1AdvancedSlicePrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1AdvancedSliceFields>>;
};

export type ContentPostBody1AdvancedSliceFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
  readonly content_post?: Maybe<Linkable>;
};

export type ContentPostBody1AdvancedSlicePrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly text_and_image_alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly use_image_as_background?: Maybe<Scalars['Boolean']>;
  readonly image_background_alignment?: Maybe<Scalars['String']>;
  readonly repeatable_layout?: Maybe<Scalars['String']>;
};

export type ContentPostBody1AdvancedTable = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1AdvancedTablePrimary>;
};

export type ContentPostBody1AdvancedTablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption?: Maybe<Scalars['Json']>;
  readonly csv_file?: Maybe<Linkable>;
};

export type ContentPostBody1BenefitQuadrant = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1BenefitQuadrantPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1BenefitQuadrantFields>>;
};

export type ContentPostBody1BenefitQuadrantFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1BenefitQuadrantPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['Json']>;
  readonly footer?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1CapabilityList = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1CapabilityListPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1CapabilityListFields>>;
};

export type ContentPostBody1CapabilityListFields = {
  readonly capability?: Maybe<Linkable>;
};

export type ContentPostBody1CapabilityListPrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type ContentPostBody1CarbonOffset = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1CarbonOffsetPrimary>;
};

export type ContentPostBody1CarbonOffsetPrimary = {
  readonly header?: Maybe<Scalars['Json']>;
  readonly text?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1CenteredFullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1CenteredFullWidthCtaPrimary>;
};

export type ContentPostBody1CenteredFullWidthCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
};

export type ContentPostBody1ContentPost = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1ContentPostPrimary>;
};

export type ContentPostBody1ContentPostPrimary = {
  readonly content_post?: Maybe<Linkable>;
  readonly content_icon?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1CountUp = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1CountUpPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1CountUpFields>>;
};

export type ContentPostBody1CountUpFields = {
  readonly category_image?: Maybe<Scalars['Json']>;
  readonly category_title?: Maybe<Scalars['String']>;
  readonly number?: Maybe<Scalars['Float']>;
  readonly suffix?: Maybe<Scalars['String']>;
};

export type ContentPostBody1CountUpPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly count_up_subtitle?: Maybe<Scalars['String']>;
};

export type ContentPostBody1FeaturedCustomers = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1FeaturedCustomersPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1FeaturedCustomersFields>>;
};

export type ContentPostBody1FeaturedCustomersFields = {
  readonly company_logo?: Maybe<Scalars['Json']>;
  readonly company_link?: Maybe<Linkable>;
};

export type ContentPostBody1FeaturedCustomersPrimary = {
  readonly subtitle?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1FullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1FullWidthCtaPrimary>;
};

export type ContentPostBody1FullWidthCtaPrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly fine_print?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1HeroAuthor = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1HeroAuthorPrimary>;
};

export type ContentPostBody1HeroAuthorPrimary = {
  readonly author?: Maybe<Linkable>;
};

export type ContentPostBody1ImageAndCaption = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1ImageAndCaptionPrimary>;
};

export type ContentPostBody1ImageAndCaptionPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly caption?: Maybe<Scalars['String']>;
};

export type ContentPostBody1ImageAndList = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1ImageAndListPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1ImageAndListFields>>;
};

export type ContentPostBody1ImageAndListFields = {
  readonly image1?: Maybe<Scalars['Json']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1ImageAndListPrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type ContentPostBody1ImageCarousel = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1ImageCarouselPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1ImageCarouselFields>>;
};

export type ContentPostBody1ImageCarouselFields = {
  readonly image1?: Maybe<Scalars['Json']>;
  readonly image_caption?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1ImageCarouselPrimary = {
  readonly primary_caption?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1ImageCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1ImageCtaPrimary>;
};

export type ContentPostBody1ImageCtaPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1ImageGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1ImageGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1ImageGridFields>>;
};

export type ContentPostBody1ImageGridFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1ImageGridPrimary = {
  readonly columns?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type ContentPostBody1InlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1InlineCtaPrimary>;
};

export type ContentPostBody1InlineCtaPrimary = {
  readonly copy?: Maybe<Scalars['String']>;
  readonly cta_text1?: Maybe<Scalars['String']>;
  readonly cta_link1?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1LatestCapabilities = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1LatestCapabilitiesPrimary>;
};

export type ContentPostBody1LatestCapabilitiesPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly publication_date_sort_order?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly number_of_post?: Maybe<Scalars['String']>;
};

export type ContentPostBody1LinkGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1LinkGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1LinkGridFields>>;
};

export type ContentPostBody1LinkGridFields = {
  readonly link_text?: Maybe<Scalars['String']>;
  readonly link?: Maybe<Linkable>;
};

export type ContentPostBody1LinkGridPrimary = {
  readonly link_grid_title?: Maybe<Scalars['String']>;
};

export type ContentPostBody1MediaMarquee = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1MediaMarqueePrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1MediaMarqueeFields>>;
};

export type ContentPostBody1MediaMarqueeFields = {
  readonly feature_image?: Maybe<Scalars['Json']>;
  readonly feature_title?: Maybe<Scalars['String']>;
  readonly feature_description?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1MediaMarqueePrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly media_marquee_description?: Maybe<Scalars['Json']>;
  readonly video_url?: Maybe<Scalars['Json']>;
  readonly footer?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1NestedContent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1NestedContentPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1NestedContentFields>>;
};

export type ContentPostBody1NestedContentFields = {
  readonly header?: Maybe<Scalars['String']>;
  readonly content?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1NestedContentPrimary = {
  readonly width?: Maybe<Scalars['String']>;
  readonly is_faq?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly title_alignment?: Maybe<Scalars['String']>;
  readonly expand_first_item?: Maybe<Scalars['Boolean']>;
  readonly content_layout?: Maybe<Scalars['String']>;
};

export type ContentPostBody1NewsletterSignup = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1NewsletterSignupPrimary>;
};

export type ContentPostBody1NewsletterSignupPrimary = {
  readonly description?: Maybe<Scalars['String']>;
  readonly cta_button_text?: Maybe<Scalars['String']>;
};

export type ContentPostBody1PullQuote = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1PullQuotePrimary>;
};

export type ContentPostBody1PullQuotePrimary = {
  readonly quote?: Maybe<Scalars['String']>;
  readonly attribution_name?: Maybe<Scalars['String']>;
  readonly attribution_title?: Maybe<Scalars['String']>;
};

export type ContentPostBody1QueriedResourcePosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1QueriedResourcePostsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1QueriedResourcePostsFields>>;
};

export type ContentPostBody1QueriedResourcePostsFields = {
  readonly content_category_filter?: Maybe<Linkable>;
  readonly author_filter?: Maybe<Linkable>;
  readonly hide_author_name?: Maybe<Scalars['Boolean']>;
};

export type ContentPostBody1QueriedResourcePostsPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly publication_date_sort_order?: Maybe<Scalars['String']>;
  readonly number_of_posts?: Maybe<Scalars['String']>;
  readonly content_type_filter?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
};

export type ContentPostBody1QuoteUploadComponent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1QuoteUploadComponentPrimary>;
};

export type ContentPostBody1QuoteUploadComponentPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly upload_component_heading?: Maybe<Scalars['String']>;
  readonly upload_component_cta?: Maybe<Scalars['String']>;
};

export type ContentPostBody1RepeatableRichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1RepeatableRichTextPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1RepeatableRichTextFields>>;
};

export type ContentPostBody1RepeatableRichTextFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1RepeatableRichTextPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1ResourcesPosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1ResourcesPostsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1ResourcesPostsFields>>;
};

export type ContentPostBody1ResourcesPostsFields = {
  readonly content_post?: Maybe<Linkable>;
};

export type ContentPostBody1ResourcesPostsPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly button_text?: Maybe<Scalars['String']>;
};

export type ContentPostBody1ReusableCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1ReusableCtaPrimary>;
};

export type ContentPostBody1ReusableCtaPrimary = {
  readonly link_to_document?: Maybe<Linkable>;
};

export type ContentPostBody1RichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1RichTextPrimary>;
};

export type ContentPostBody1RichTextPrimary = {
  readonly highlight_section?: Maybe<Scalars['Boolean']>;
  readonly title1?: Maybe<Scalars['String']>;
  readonly rich_text?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1SectionDivider = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1SectionDividerPrimary>;
};

export type ContentPostBody1SectionDividerPrimary = {
  readonly transition?: Maybe<Scalars['String']>;
  readonly illustration?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1SimpleInlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1SimpleInlineCtaPrimary>;
};

export type ContentPostBody1SimpleInlineCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
};

export type ContentPostBody1Table = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1TablePrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1TableFields>>;
};

export type ContentPostBody1TableFields = {
  readonly column_1_entry?: Maybe<Scalars['Json']>;
  readonly column_2_entry?: Maybe<Scalars['Json']>;
  readonly column_3_entry?: Maybe<Scalars['Json']>;
  readonly column_4_entry?: Maybe<Scalars['Json']>;
  readonly column_5_entry?: Maybe<Scalars['Json']>;
  readonly column_6_entry?: Maybe<Scalars['Json']>;
  readonly column_7_entry?: Maybe<Scalars['Json']>;
  readonly column_8_entry?: Maybe<Scalars['Json']>;
  readonly column_9_entry?: Maybe<Scalars['Json']>;
  readonly column_10_entry?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1TablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption_rich_text?: Maybe<Scalars['Json']>;
  readonly column_1_header?: Maybe<Scalars['String']>;
  readonly column_2_header?: Maybe<Scalars['String']>;
  readonly column_3_header?: Maybe<Scalars['String']>;
  readonly column_4_header?: Maybe<Scalars['String']>;
  readonly column_5_header?: Maybe<Scalars['String']>;
  readonly column_6_header?: Maybe<Scalars['String']>;
  readonly column_7_header?: Maybe<Scalars['String']>;
  readonly column_8_header?: Maybe<Scalars['String']>;
  readonly column_9_header?: Maybe<Scalars['String']>;
  readonly column_10_header?: Maybe<Scalars['String']>;
};

export type ContentPostBody1Testimonial = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1TestimonialPrimary>;
};

export type ContentPostBody1TestimonialPrimary = {
  readonly quote?: Maybe<Scalars['String']>;
  readonly person_name?: Maybe<Scalars['String']>;
  readonly person_title?: Maybe<Scalars['String']>;
  readonly person_image?: Maybe<Scalars['Json']>;
  readonly company_image?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1Testimonials = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1TestimonialsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1TestimonialsFields>>;
};

export type ContentPostBody1TestimonialsFields = {
  readonly person_image?: Maybe<Scalars['Json']>;
  readonly company_image?: Maybe<Scalars['Json']>;
  readonly quote?: Maybe<Scalars['String']>;
  readonly person_name?: Maybe<Scalars['String']>;
  readonly person_title?: Maybe<Scalars['String']>;
};

export type ContentPostBody1TestimonialsPrimary = {
  readonly title?: Maybe<Scalars['String']>;
};

export type ContentPostBody1TextAndImage = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1TextAndImagePrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1TextAndImageFields>>;
};

export type ContentPostBody1TextAndImageFields = {
  readonly bullet_point?: Maybe<Scalars['String']>;
};

export type ContentPostBody1TextAndImagePrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly display_new_badge?: Maybe<Scalars['Boolean']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1TextAndImageRows = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1TextAndImageRowsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1TextAndImageRowsFields>>;
};

export type ContentPostBody1TextAndImageRowsFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1TextAndImageRowsPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1TextAndImageV2 = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1TextAndImageV2Primary>;
};

export type ContentPostBody1TextAndImageV2Primary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly use_image_as_background?: Maybe<Scalars['Boolean']>;
  readonly image_background_alignment?: Maybe<Scalars['String']>;
};

export type ContentPostBody1TextAndModelFile = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1TextAndModelFilePrimary>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBody1TextAndModelFileFields>>;
};

export type ContentPostBody1TextAndModelFileFields = {
  readonly bullet_point?: Maybe<Scalars['String']>;
};

export type ContentPostBody1TextAndModelFilePrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly display_new_badge?: Maybe<Scalars['Boolean']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly model_file?: Maybe<Linkable>;
  readonly cta_text?: Maybe<Scalars['String']>;
};

export type ContentPostBody1ThreeSimpleCards = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1ThreeSimpleCardsPrimary>;
};

export type ContentPostBody1ThreeSimpleCardsPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly card_1_image?: Maybe<Scalars['Json']>;
  readonly card_1_title?: Maybe<Scalars['String']>;
  readonly card_1_description?: Maybe<Scalars['Json']>;
  readonly card_2_image?: Maybe<Scalars['Json']>;
  readonly card_2_title?: Maybe<Scalars['String']>;
  readonly card_2_description?: Maybe<Scalars['Json']>;
  readonly card_3_image?: Maybe<Scalars['Json']>;
  readonly card_3_title?: Maybe<Scalars['String']>;
  readonly card_3_description?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1ThreeSimpleCircles = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1ThreeSimpleCirclesPrimary>;
};

export type ContentPostBody1ThreeSimpleCirclesPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly circle_1_image?: Maybe<Scalars['Json']>;
  readonly circle_1_title?: Maybe<Scalars['String']>;
  readonly circle_1_description?: Maybe<Scalars['Json']>;
  readonly circle_2_image?: Maybe<Scalars['Json']>;
  readonly circle_2_title?: Maybe<Scalars['String']>;
  readonly circle_2_description?: Maybe<Scalars['Json']>;
  readonly circle_3_image?: Maybe<Scalars['Json']>;
  readonly circle_3_title?: Maybe<Scalars['String']>;
  readonly circle_3_description?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1VideoCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1VideoCtaPrimary>;
};

export type ContentPostBody1VideoCtaPrimary = {
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly video_url?: Maybe<Scalars['Json']>;
};

export type ContentPostBody1XometryEverywhere = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
};

export type ContentPostBody1YoutubeVideo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBody1YoutubeVideoPrimary>;
};

export type ContentPostBody1YoutubeVideoPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['Json']>;
};

export type ContentPostBodyPageLocales = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<ContentPostBodyPageLocalesFields>>;
};

export type ContentPostBodyPageLocalesFields = {
  readonly locale?: Maybe<Scalars['String']>;
  readonly page?: Maybe<Linkable>;
};

export type ContentPostBodySeo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ContentPostBodySeoPrimary>;
};

export type ContentPostBodySeoPrimary = {
  readonly meta_title?: Maybe<Scalars['String']>;
  readonly meta_description?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type ContentPostConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<ContentPostConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type ContentPostConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: ContentPost;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type CtaContent = Document & Linkable & {
  readonly category?: Maybe<Linkable>;
  readonly title?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CtaContentConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<CtaContentConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type CtaContentConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: CtaContent;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};



export type Footer = Document & Linkable & {
  readonly column_title_1?: Maybe<Scalars['String']>;
  readonly column_text_1?: Maybe<ReadonlyArray<FooterColumnText_1>>;
  readonly column_title_2?: Maybe<Scalars['String']>;
  readonly column_text_2?: Maybe<ReadonlyArray<FooterColumnText_2>>;
  readonly column_title_3?: Maybe<Scalars['String']>;
  readonly column_text_3?: Maybe<ReadonlyArray<FooterColumnText_3>>;
  readonly legal_links?: Maybe<ReadonlyArray<FooterLegalLinks>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type FooterColumnText_1 = {
  readonly column_item?: Maybe<Scalars['Json']>;
};

export type FooterColumnText_2 = {
  readonly column_item?: Maybe<Scalars['Json']>;
};

export type FooterColumnText_3 = {
  readonly column_item?: Maybe<Scalars['Json']>;
};

/** A connection to a list of items. */
export type FooterConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<FooterConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type FooterConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: Footer;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type FooterLegalLinks = {
  readonly legal_link?: Maybe<Scalars['Json']>;
};

export type GoGreen = Document & Linkable & {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['String']>;
  readonly hero_image?: Maybe<Scalars['Json']>;
  readonly text_above_reporter_element?: Maybe<Scalars['Json']>;
  readonly text_below_reporter_element?: Maybe<Scalars['Json']>;
  readonly body?: Maybe<ReadonlyArray<GoGreenBody>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type GoGreenBody = GoGreenBodyPageLocales;

export type GoGreenBodyPageLocales = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<GoGreenBodyPageLocalesFields>>;
};

export type GoGreenBodyPageLocalesFields = {
  readonly locale?: Maybe<Scalars['String']>;
  readonly page?: Maybe<Linkable>;
};

/** A connection to a list of items. */
export type GoGreenConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<GoGreenConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type GoGreenConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: GoGreen;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type Header = Document & Linkable & {
  readonly menu_links?: Maybe<ReadonlyArray<HeaderMenuLinks>>;
  readonly menu_ctas?: Maybe<ReadonlyArray<HeaderMenuCtas>>;
  readonly body?: Maybe<ReadonlyArray<HeaderBody>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type HeaderBody = HeaderBodyDrawerButton | HeaderBodyDrawerColumn;

export type HeaderBodyDrawerButton = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HeaderBodyDrawerButtonPrimary>;
};

export type HeaderBodyDrawerButtonPrimary = {
  readonly drawer_button_text?: Maybe<Scalars['String']>;
};

export type HeaderBodyDrawerColumn = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HeaderBodyDrawerColumnPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HeaderBodyDrawerColumnFields>>;
};

export type HeaderBodyDrawerColumnFields = {
  readonly link?: Maybe<Scalars['Json']>;
};

export type HeaderBodyDrawerColumnPrimary = {
  readonly column_title?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type HeaderConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<HeaderConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type HeaderConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: Header;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type HeaderMenuCtas = {
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
};

export type HeaderMenuLinks = {
  readonly link_text?: Maybe<Scalars['String']>;
  readonly link?: Maybe<Linkable>;
};

export type HeaderCtas = Document & Linkable & {
  readonly ctas?: Maybe<ReadonlyArray<HeaderCtasCtas>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type HeaderCtasConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<HeaderCtasConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type HeaderCtasConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: HeaderCtas;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type HeaderCtasCtas = {
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
};

export type HeaderLinks = Document & Linkable & {
  readonly page_links?: Maybe<ReadonlyArray<HeaderLinksPageLinks>>;
  readonly body?: Maybe<ReadonlyArray<HeaderLinksBody>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type HeaderLinksBody = HeaderLinksBodyDrawerButton | HeaderLinksBodyDrawerColumn;

export type HeaderLinksBodyDrawerButton = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HeaderLinksBodyDrawerButtonPrimary>;
};

export type HeaderLinksBodyDrawerButtonPrimary = {
  readonly drawer_button_text?: Maybe<Scalars['String']>;
};

export type HeaderLinksBodyDrawerColumn = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HeaderLinksBodyDrawerColumnPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HeaderLinksBodyDrawerColumnFields>>;
};

export type HeaderLinksBodyDrawerColumnFields = {
  readonly link?: Maybe<Scalars['Json']>;
};

export type HeaderLinksBodyDrawerColumnPrimary = {
  readonly column_title?: Maybe<Scalars['String']>;
  readonly column_link?: Maybe<Linkable>;
};

/** A connection to a list of items. */
export type HeaderLinksConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<HeaderLinksConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type HeaderLinksConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: HeaderLinks;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type HeaderLinksPageLinks = {
  readonly link_text?: Maybe<Scalars['String']>;
  readonly link?: Maybe<Linkable>;
};

export type Home = Document & Linkable & {
  readonly body1?: Maybe<ReadonlyArray<HomeBody1>>;
  readonly body?: Maybe<ReadonlyArray<HomeBody>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type HomeBody = HomeBodySeo | HomeBodyPageLocales;

export type HomeBody1 = HomeBody1HomeHero | HomeBody1CountUp | HomeBody1FeaturedCustomers | HomeBody1BenefitQuadrant | HomeBody1MediaMarquee | HomeBody1TextAndImage | HomeBody1Testimonials | HomeBody1SimpleInlineCta | HomeBody1ContentPost | HomeBody1ResourcesPosts | HomeBody1NewsletterSignup | HomeBody1CenteredFullWidthCta | HomeBody1QueriedResourcePosts | HomeBody1RichText | HomeBody1ImageAndCaption | HomeBody1AdvancedTable | HomeBody1CapabilityList | HomeBody1CarbonOffset | HomeBody1FullWidthCta | HomeBody1YoutubeVideo | HomeBody1NestedContent | HomeBody1ImageAndList | HomeBody1ImageCarousel | HomeBody1LinkGrid | HomeBody1InlineCta | HomeBody1ImageGrid | HomeBody1ThreeSimpleCards | HomeBody1ThreeSimpleCircles | HomeBody1Testimonial | HomeBody1Table | HomeBody1PullQuote | HomeBody1XometryEverywhere | HomeBody1TextAndModelFile | HomeBody1QuoteUploadComponent | HomeBody1LatestCapabilities | HomeBody1VideoCta | HomeBody1ImageCta | HomeBody1TextAndImageRows | HomeBody1ReusableCta | HomeBody1RepeatableRichText | HomeBody1TextAndImageV2 | HomeBody1SectionDivider | HomeBody1AdvancedSlice | HomeBody1HeroAuthor;

export type HomeBody1AdvancedSlice = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1AdvancedSlicePrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1AdvancedSliceFields>>;
};

export type HomeBody1AdvancedSliceFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
  readonly content_post?: Maybe<Linkable>;
};

export type HomeBody1AdvancedSlicePrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly text_and_image_alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly use_image_as_background?: Maybe<Scalars['Boolean']>;
  readonly image_background_alignment?: Maybe<Scalars['String']>;
  readonly repeatable_layout?: Maybe<Scalars['String']>;
};

export type HomeBody1AdvancedTable = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1AdvancedTablePrimary>;
};

export type HomeBody1AdvancedTablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption?: Maybe<Scalars['Json']>;
  readonly csv_file?: Maybe<Linkable>;
};

export type HomeBody1BenefitQuadrant = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1BenefitQuadrantPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1BenefitQuadrantFields>>;
};

export type HomeBody1BenefitQuadrantFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type HomeBody1BenefitQuadrantPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['Json']>;
  readonly footer?: Maybe<Scalars['Json']>;
};

export type HomeBody1CapabilityList = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1CapabilityListPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1CapabilityListFields>>;
};

export type HomeBody1CapabilityListFields = {
  readonly capability?: Maybe<Linkable>;
};

export type HomeBody1CapabilityListPrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type HomeBody1CarbonOffset = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1CarbonOffsetPrimary>;
};

export type HomeBody1CarbonOffsetPrimary = {
  readonly header?: Maybe<Scalars['Json']>;
  readonly text?: Maybe<Scalars['Json']>;
};

export type HomeBody1CenteredFullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1CenteredFullWidthCtaPrimary>;
};

export type HomeBody1CenteredFullWidthCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
};

export type HomeBody1ContentPost = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1ContentPostPrimary>;
};

export type HomeBody1ContentPostPrimary = {
  readonly content_post?: Maybe<Linkable>;
  readonly content_icon?: Maybe<Scalars['Json']>;
};

export type HomeBody1CountUp = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1CountUpPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1CountUpFields>>;
};

export type HomeBody1CountUpFields = {
  readonly category_image?: Maybe<Scalars['Json']>;
  readonly category_title?: Maybe<Scalars['String']>;
  readonly number?: Maybe<Scalars['Float']>;
  readonly suffix?: Maybe<Scalars['String']>;
};

export type HomeBody1CountUpPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly count_up_subtitle?: Maybe<Scalars['String']>;
};

export type HomeBody1FeaturedCustomers = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1FeaturedCustomersPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1FeaturedCustomersFields>>;
};

export type HomeBody1FeaturedCustomersFields = {
  readonly company_logo?: Maybe<Scalars['Json']>;
  readonly company_link?: Maybe<Linkable>;
};

export type HomeBody1FeaturedCustomersPrimary = {
  readonly subtitle?: Maybe<Scalars['Json']>;
};

export type HomeBody1FullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1FullWidthCtaPrimary>;
};

export type HomeBody1FullWidthCtaPrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly fine_print?: Maybe<Scalars['Json']>;
};

export type HomeBody1HeroAuthor = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1HeroAuthorPrimary>;
};

export type HomeBody1HeroAuthorPrimary = {
  readonly author?: Maybe<Linkable>;
};

export type HomeBody1HomeHero = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1HomeHeroPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1HomeHeroFields>>;
};

export type HomeBody1HomeHeroFields = {
  readonly card_image?: Maybe<Scalars['Json']>;
  readonly card_title?: Maybe<Scalars['String']>;
  readonly primary_link_text?: Maybe<Scalars['String']>;
  readonly primary_link?: Maybe<Linkable>;
  readonly secondary_link_text?: Maybe<Scalars['String']>;
  readonly secondary_link?: Maybe<Linkable>;
};

export type HomeBody1HomeHeroPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly quote_cta_title?: Maybe<Scalars['String']>;
  readonly quote_cta_subtitle?: Maybe<Scalars['Json']>;
  readonly quote_cta_text?: Maybe<Scalars['String']>;
  readonly quote_cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly quick_fact_1_text?: Maybe<Scalars['Json']>;
  readonly quick_fact_1_icon?: Maybe<Scalars['String']>;
  readonly quick_fact_2_text?: Maybe<Scalars['Json']>;
  readonly quick_fact_2_icon?: Maybe<Scalars['String']>;
  readonly quick_fact_3_text?: Maybe<Scalars['Json']>;
  readonly quick_fact_3_icon?: Maybe<Scalars['String']>;
  readonly primary_cta_text?: Maybe<Scalars['String']>;
  readonly primary_cta_action?: Maybe<Scalars['String']>;
  readonly primary_cta_link?: Maybe<Linkable>;
  readonly primary_drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly secondary_cta_text?: Maybe<Scalars['String']>;
  readonly secondary_cta_action?: Maybe<Scalars['String']>;
  readonly secondary_cta_link?: Maybe<Linkable>;
  readonly secondary_drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_microcopy?: Maybe<Scalars['Boolean']>;
  readonly video_cta_text?: Maybe<Scalars['String']>;
  readonly video_cta?: Maybe<Scalars['Json']>;
};

export type HomeBody1ImageAndCaption = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1ImageAndCaptionPrimary>;
};

export type HomeBody1ImageAndCaptionPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly caption?: Maybe<Scalars['String']>;
};

export type HomeBody1ImageAndList = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1ImageAndListPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1ImageAndListFields>>;
};

export type HomeBody1ImageAndListFields = {
  readonly image1?: Maybe<Scalars['Json']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type HomeBody1ImageAndListPrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type HomeBody1ImageCarousel = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1ImageCarouselPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1ImageCarouselFields>>;
};

export type HomeBody1ImageCarouselFields = {
  readonly image1?: Maybe<Scalars['Json']>;
  readonly image_caption?: Maybe<Scalars['Json']>;
};

export type HomeBody1ImageCarouselPrimary = {
  readonly primary_caption?: Maybe<Scalars['Json']>;
};

export type HomeBody1ImageCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1ImageCtaPrimary>;
};

export type HomeBody1ImageCtaPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type HomeBody1ImageGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1ImageGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1ImageGridFields>>;
};

export type HomeBody1ImageGridFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type HomeBody1ImageGridPrimary = {
  readonly columns?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type HomeBody1InlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1InlineCtaPrimary>;
};

export type HomeBody1InlineCtaPrimary = {
  readonly copy?: Maybe<Scalars['String']>;
  readonly cta_text1?: Maybe<Scalars['String']>;
  readonly cta_link1?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type HomeBody1LatestCapabilities = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1LatestCapabilitiesPrimary>;
};

export type HomeBody1LatestCapabilitiesPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly publication_date_sort_order?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly number_of_post?: Maybe<Scalars['String']>;
};

export type HomeBody1LinkGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1LinkGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1LinkGridFields>>;
};

export type HomeBody1LinkGridFields = {
  readonly link_text?: Maybe<Scalars['String']>;
  readonly link?: Maybe<Linkable>;
};

export type HomeBody1LinkGridPrimary = {
  readonly link_grid_title?: Maybe<Scalars['String']>;
};

export type HomeBody1MediaMarquee = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1MediaMarqueePrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1MediaMarqueeFields>>;
};

export type HomeBody1MediaMarqueeFields = {
  readonly feature_image?: Maybe<Scalars['Json']>;
  readonly feature_title?: Maybe<Scalars['String']>;
  readonly feature_description?: Maybe<Scalars['Json']>;
};

export type HomeBody1MediaMarqueePrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly media_marquee_description?: Maybe<Scalars['Json']>;
  readonly video_url?: Maybe<Scalars['Json']>;
  readonly footer?: Maybe<Scalars['Json']>;
};

export type HomeBody1NestedContent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1NestedContentPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1NestedContentFields>>;
};

export type HomeBody1NestedContentFields = {
  readonly header?: Maybe<Scalars['String']>;
  readonly content?: Maybe<Scalars['Json']>;
};

export type HomeBody1NestedContentPrimary = {
  readonly width?: Maybe<Scalars['String']>;
  readonly is_faq?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly title_alignment?: Maybe<Scalars['String']>;
  readonly expand_first_item?: Maybe<Scalars['Boolean']>;
  readonly content_layout?: Maybe<Scalars['String']>;
};

export type HomeBody1NewsletterSignup = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1NewsletterSignupPrimary>;
};

export type HomeBody1NewsletterSignupPrimary = {
  readonly description?: Maybe<Scalars['String']>;
  readonly cta_button_text?: Maybe<Scalars['String']>;
};

export type HomeBody1PullQuote = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1PullQuotePrimary>;
};

export type HomeBody1PullQuotePrimary = {
  readonly quote?: Maybe<Scalars['String']>;
  readonly attribution_name?: Maybe<Scalars['String']>;
  readonly attribution_title?: Maybe<Scalars['String']>;
};

export type HomeBody1QueriedResourcePosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1QueriedResourcePostsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1QueriedResourcePostsFields>>;
};

export type HomeBody1QueriedResourcePostsFields = {
  readonly content_category_filter?: Maybe<Linkable>;
  readonly author_filter?: Maybe<Linkable>;
  readonly hide_author_name?: Maybe<Scalars['Boolean']>;
};

export type HomeBody1QueriedResourcePostsPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly publication_date_sort_order?: Maybe<Scalars['String']>;
  readonly number_of_posts?: Maybe<Scalars['String']>;
  readonly content_type_filter?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
};

export type HomeBody1QuoteUploadComponent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1QuoteUploadComponentPrimary>;
};

export type HomeBody1QuoteUploadComponentPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly upload_component_heading?: Maybe<Scalars['String']>;
  readonly upload_component_cta?: Maybe<Scalars['String']>;
};

export type HomeBody1RepeatableRichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1RepeatableRichTextPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1RepeatableRichTextFields>>;
};

export type HomeBody1RepeatableRichTextFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type HomeBody1RepeatableRichTextPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
};

export type HomeBody1ResourcesPosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1ResourcesPostsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1ResourcesPostsFields>>;
};

export type HomeBody1ResourcesPostsFields = {
  readonly content_post?: Maybe<Linkable>;
};

export type HomeBody1ResourcesPostsPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly button_text?: Maybe<Scalars['String']>;
};

export type HomeBody1ReusableCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1ReusableCtaPrimary>;
};

export type HomeBody1ReusableCtaPrimary = {
  readonly link_to_document?: Maybe<Linkable>;
};

export type HomeBody1RichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1RichTextPrimary>;
};

export type HomeBody1RichTextPrimary = {
  readonly highlight_section?: Maybe<Scalars['Boolean']>;
  readonly title1?: Maybe<Scalars['String']>;
  readonly rich_text?: Maybe<Scalars['Json']>;
};

export type HomeBody1SectionDivider = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1SectionDividerPrimary>;
};

export type HomeBody1SectionDividerPrimary = {
  readonly transition?: Maybe<Scalars['String']>;
  readonly illustration?: Maybe<Scalars['Json']>;
};

export type HomeBody1SimpleInlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1SimpleInlineCtaPrimary>;
};

export type HomeBody1SimpleInlineCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
};

export type HomeBody1Table = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1TablePrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1TableFields>>;
};

export type HomeBody1TableFields = {
  readonly column_1_entry?: Maybe<Scalars['Json']>;
  readonly column_2_entry?: Maybe<Scalars['Json']>;
  readonly column_3_entry?: Maybe<Scalars['Json']>;
  readonly column_4_entry?: Maybe<Scalars['Json']>;
  readonly column_5_entry?: Maybe<Scalars['Json']>;
  readonly column_6_entry?: Maybe<Scalars['Json']>;
  readonly column_7_entry?: Maybe<Scalars['Json']>;
  readonly column_8_entry?: Maybe<Scalars['Json']>;
  readonly column_9_entry?: Maybe<Scalars['Json']>;
  readonly column_10_entry?: Maybe<Scalars['Json']>;
};

export type HomeBody1TablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption_rich_text?: Maybe<Scalars['Json']>;
  readonly column_1_header?: Maybe<Scalars['String']>;
  readonly column_2_header?: Maybe<Scalars['String']>;
  readonly column_3_header?: Maybe<Scalars['String']>;
  readonly column_4_header?: Maybe<Scalars['String']>;
  readonly column_5_header?: Maybe<Scalars['String']>;
  readonly column_6_header?: Maybe<Scalars['String']>;
  readonly column_7_header?: Maybe<Scalars['String']>;
  readonly column_8_header?: Maybe<Scalars['String']>;
  readonly column_9_header?: Maybe<Scalars['String']>;
  readonly column_10_header?: Maybe<Scalars['String']>;
};

export type HomeBody1Testimonial = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1TestimonialPrimary>;
};

export type HomeBody1TestimonialPrimary = {
  readonly quote?: Maybe<Scalars['String']>;
  readonly person_name?: Maybe<Scalars['String']>;
  readonly person_title?: Maybe<Scalars['String']>;
  readonly person_image?: Maybe<Scalars['Json']>;
  readonly company_image?: Maybe<Scalars['Json']>;
};

export type HomeBody1Testimonials = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1TestimonialsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1TestimonialsFields>>;
};

export type HomeBody1TestimonialsFields = {
  readonly person_image?: Maybe<Scalars['Json']>;
  readonly company_image?: Maybe<Scalars['Json']>;
  readonly quote?: Maybe<Scalars['String']>;
  readonly person_name?: Maybe<Scalars['String']>;
  readonly person_title?: Maybe<Scalars['String']>;
};

export type HomeBody1TestimonialsPrimary = {
  readonly title?: Maybe<Scalars['String']>;
};

export type HomeBody1TextAndImage = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1TextAndImagePrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1TextAndImageFields>>;
};

export type HomeBody1TextAndImageFields = {
  readonly bullet_point?: Maybe<Scalars['String']>;
};

export type HomeBody1TextAndImagePrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly display_new_badge?: Maybe<Scalars['Boolean']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type HomeBody1TextAndImageRows = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1TextAndImageRowsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1TextAndImageRowsFields>>;
};

export type HomeBody1TextAndImageRowsFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type HomeBody1TextAndImageRowsPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
};

export type HomeBody1TextAndImageV2 = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1TextAndImageV2Primary>;
};

export type HomeBody1TextAndImageV2Primary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly use_image_as_background?: Maybe<Scalars['Boolean']>;
  readonly image_background_alignment?: Maybe<Scalars['String']>;
};

export type HomeBody1TextAndModelFile = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1TextAndModelFilePrimary>;
  readonly fields?: Maybe<ReadonlyArray<HomeBody1TextAndModelFileFields>>;
};

export type HomeBody1TextAndModelFileFields = {
  readonly bullet_point?: Maybe<Scalars['String']>;
};

export type HomeBody1TextAndModelFilePrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly display_new_badge?: Maybe<Scalars['Boolean']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly model_file?: Maybe<Linkable>;
  readonly cta_text?: Maybe<Scalars['String']>;
};

export type HomeBody1ThreeSimpleCards = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1ThreeSimpleCardsPrimary>;
};

export type HomeBody1ThreeSimpleCardsPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly card_1_image?: Maybe<Scalars['Json']>;
  readonly card_1_title?: Maybe<Scalars['String']>;
  readonly card_1_description?: Maybe<Scalars['Json']>;
  readonly card_2_image?: Maybe<Scalars['Json']>;
  readonly card_2_title?: Maybe<Scalars['String']>;
  readonly card_2_description?: Maybe<Scalars['Json']>;
  readonly card_3_image?: Maybe<Scalars['Json']>;
  readonly card_3_title?: Maybe<Scalars['String']>;
  readonly card_3_description?: Maybe<Scalars['Json']>;
};

export type HomeBody1ThreeSimpleCircles = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1ThreeSimpleCirclesPrimary>;
};

export type HomeBody1ThreeSimpleCirclesPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly circle_1_image?: Maybe<Scalars['Json']>;
  readonly circle_1_title?: Maybe<Scalars['String']>;
  readonly circle_1_description?: Maybe<Scalars['Json']>;
  readonly circle_2_image?: Maybe<Scalars['Json']>;
  readonly circle_2_title?: Maybe<Scalars['String']>;
  readonly circle_2_description?: Maybe<Scalars['Json']>;
  readonly circle_3_image?: Maybe<Scalars['Json']>;
  readonly circle_3_title?: Maybe<Scalars['String']>;
  readonly circle_3_description?: Maybe<Scalars['Json']>;
};

export type HomeBody1VideoCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1VideoCtaPrimary>;
};

export type HomeBody1VideoCtaPrimary = {
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly video_url?: Maybe<Scalars['Json']>;
};

export type HomeBody1XometryEverywhere = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
};

export type HomeBody1YoutubeVideo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBody1YoutubeVideoPrimary>;
};

export type HomeBody1YoutubeVideoPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['Json']>;
};

export type HomeBodyPageLocales = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<HomeBodyPageLocalesFields>>;
};

export type HomeBodyPageLocalesFields = {
  readonly locale?: Maybe<Scalars['String']>;
  readonly page?: Maybe<Linkable>;
};

export type HomeBodySeo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<HomeBodySeoPrimary>;
};

export type HomeBodySeoPrimary = {
  readonly meta_title?: Maybe<Scalars['String']>;
  readonly meta_description?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type HomeConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<HomeConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type HomeConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: Home;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type JobConfirmationBanners = Document & Linkable & {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type JobConfirmationBannersConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<JobConfirmationBannersConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type JobConfirmationBannersConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: JobConfirmationBanners;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};


export type LandingPageGroup = Document & Linkable & {
  readonly body?: Maybe<ReadonlyArray<LandingPageGroupBody>>;
  readonly processes?: Maybe<ReadonlyArray<LandingPageGroupProcesses>>;
  readonly seo?: Maybe<ReadonlyArray<LandingPageGroupSeo>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBody = LandingPageGroupBodyLandingPageHero | LandingPageGroupBodyTestimonial | LandingPageGroupBodyThreeSimpleCards | LandingPageGroupBodyYoutubeVideo | LandingPageGroupBodyNestedContent | LandingPageGroupBodyCapabilityList | LandingPageGroupBodyContentPost | LandingPageGroupBodyCenteredHero | LandingPageGroupBodyResourcesPosts | LandingPageGroupBodyImageGrid | LandingPageGroupBodyRichText | LandingPageGroupBodyBenefitQuadrant | LandingPageGroupBodyImageAndList | LandingPageGroupBodyTestimonials | LandingPageGroupBodyMediaMarquee | LandingPageGroupBodyImageCarousel | LandingPageGroupBodyFeaturedCustomers | LandingPageGroupBodyNewsletterSignup | LandingPageGroupBodyFormHero | LandingPageGroupBodyTable | LandingPageGroupBodyLinkGrid | LandingPageGroupBodyCenteredFullWidthCta | LandingPageGroupBodyFullWidthCta | LandingPageGroupBodyTextAndImage | LandingPageGroupBodyThreeSimpleCircles | LandingPageGroupBodyNearbyLocations | LandingPageGroupBodyAdvancedTable | LandingPageGroupBodyQueriedResourcePosts | LandingPageGroupBodyImageAndCaption | LandingPageGroupBodyCarbonOffset | LandingPageGroupBodyCountUp | LandingPageGroupBodyPullQuote | LandingPageGroupBodyXometryEverywhere | LandingPageGroupBodyInlineCta | LandingPageGroupBodySimpleInlineCta | LandingPageGroupBodyTextAndModelFile | LandingPageGroupBodyQuoteUploadComponent | LandingPageGroupBodyCustomerRfqCta | LandingPageGroupBodyLatestCapabilities | LandingPageGroupBodyVideoCta | LandingPageGroupBodyImageCta | LandingPageGroupBodyTextAndImageRows | LandingPageGroupBodyReusableCta | LandingPageGroupBodyRepeatableRichText | LandingPageGroupBodyTextAndImageV2 | LandingPageGroupBodySectionDivider | LandingPageGroupBodyAdvancedSlice | LandingPageGroupBodyHeroAuthor;

export type LandingPageGroupBodyAdvancedSlice = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyAdvancedSlicePrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyAdvancedSliceFields>>;
};

export type LandingPageGroupBodyAdvancedSliceFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
  readonly content_post?: Maybe<Linkable>;
};

export type LandingPageGroupBodyAdvancedSlicePrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly text_and_image_alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly use_image_as_background?: Maybe<Scalars['Boolean']>;
  readonly image_background_alignment?: Maybe<Scalars['String']>;
  readonly repeatable_layout?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyAdvancedTable = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyAdvancedTablePrimary>;
};

export type LandingPageGroupBodyAdvancedTablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption?: Maybe<Scalars['Json']>;
  readonly csv_file?: Maybe<Linkable>;
};

export type LandingPageGroupBodyBenefitQuadrant = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyBenefitQuadrantPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyBenefitQuadrantFields>>;
};

export type LandingPageGroupBodyBenefitQuadrantFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyBenefitQuadrantPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['Json']>;
  readonly footer?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyCapabilityList = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyCapabilityListPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyCapabilityListFields>>;
};

export type LandingPageGroupBodyCapabilityListFields = {
  readonly capability?: Maybe<Linkable>;
};

export type LandingPageGroupBodyCapabilityListPrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyCarbonOffset = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyCarbonOffsetPrimary>;
};

export type LandingPageGroupBodyCarbonOffsetPrimary = {
  readonly header?: Maybe<Scalars['Json']>;
  readonly text?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyCenteredFullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyCenteredFullWidthCtaPrimary>;
};

export type LandingPageGroupBodyCenteredFullWidthCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
};

export type LandingPageGroupBodyCenteredHero = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyCenteredHeroPrimary>;
};

export type LandingPageGroupBodyCenteredHeroPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_microcopy?: Maybe<Scalars['Boolean']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyContentPost = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyContentPostPrimary>;
};

export type LandingPageGroupBodyContentPostPrimary = {
  readonly content_post?: Maybe<Linkable>;
  readonly content_icon?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyCountUp = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyCountUpPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyCountUpFields>>;
};

export type LandingPageGroupBodyCountUpFields = {
  readonly category_image?: Maybe<Scalars['Json']>;
  readonly category_title?: Maybe<Scalars['String']>;
  readonly number?: Maybe<Scalars['Float']>;
  readonly suffix?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyCountUpPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly count_up_subtitle?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyCustomerRfqCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyCustomerRfqCtaPrimary>;
};

export type LandingPageGroupBodyCustomerRfqCtaPrimary = {
  readonly customer_rfq_cta_subtitle?: Maybe<Scalars['Json']>;
  readonly customer_rfq_cta_button_text?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyFeaturedCustomers = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyFeaturedCustomersPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyFeaturedCustomersFields>>;
};

export type LandingPageGroupBodyFeaturedCustomersFields = {
  readonly company_logo?: Maybe<Scalars['Json']>;
  readonly company_link?: Maybe<Linkable>;
};

export type LandingPageGroupBodyFeaturedCustomersPrimary = {
  readonly subtitle?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyFormHero = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyFormHeroPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyFormHeroFields>>;
};

export type LandingPageGroupBodyFormHeroFields = {
  readonly quick_fact_text?: Maybe<Scalars['Json']>;
  readonly quick_fact_icon?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyFormHeroPrimary = {
  readonly single_column?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly form_title?: Maybe<Scalars['String']>;
  readonly form_subtitle?: Maybe<Scalars['Json']>;
  readonly hubspot_form_id?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyFullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyFullWidthCtaPrimary>;
};

export type LandingPageGroupBodyFullWidthCtaPrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly fine_print?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyHeroAuthor = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyHeroAuthorPrimary>;
};

export type LandingPageGroupBodyHeroAuthorPrimary = {
  readonly author?: Maybe<Linkable>;
};

export type LandingPageGroupBodyImageAndList = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyImageAndListPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyImageAndListFields>>;
};

export type LandingPageGroupBodyImageAndListFields = {
  readonly image1?: Maybe<Scalars['Json']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyImageAndListPrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyImageAndCaption = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyImageAndCaptionPrimary>;
};

export type LandingPageGroupBodyImageAndCaptionPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly caption?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyImageCarousel = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyImageCarouselPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyImageCarouselFields>>;
};

export type LandingPageGroupBodyImageCarouselFields = {
  readonly image1?: Maybe<Scalars['Json']>;
  readonly image_caption?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyImageCarouselPrimary = {
  readonly primary_caption?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyImageCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyImageCtaPrimary>;
};

export type LandingPageGroupBodyImageCtaPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyImageGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyImageGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyImageGridFields>>;
};

export type LandingPageGroupBodyImageGridFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyImageGridPrimary = {
  readonly columns?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyInlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyInlineCtaPrimary>;
};

export type LandingPageGroupBodyInlineCtaPrimary = {
  readonly copy?: Maybe<Scalars['String']>;
  readonly cta_text1?: Maybe<Scalars['String']>;
  readonly cta_link1?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyLandingPageHero = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyLandingPageHeroPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyLandingPageHeroFields>>;
};

export type LandingPageGroupBodyLandingPageHeroFields = {
  readonly quick_fact_text?: Maybe<Scalars['Json']>;
  readonly quick_fact_icon?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyLandingPageHeroPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_microcopy?: Maybe<Scalars['Boolean']>;
  readonly rfq_cta_type?: Maybe<Scalars['String']>;
  readonly rfq_cta_section_title?: Maybe<Scalars['String']>;
  readonly customer_rfq_prompt_text?: Maybe<Scalars['String']>;
  readonly customer_rfq_prompt_button_text?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyLatestCapabilities = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyLatestCapabilitiesPrimary>;
};

export type LandingPageGroupBodyLatestCapabilitiesPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly publication_date_sort_order?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly number_of_post?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyLinkGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyLinkGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyLinkGridFields>>;
};

export type LandingPageGroupBodyLinkGridFields = {
  readonly link_text?: Maybe<Scalars['String']>;
  readonly link?: Maybe<Linkable>;
};

export type LandingPageGroupBodyLinkGridPrimary = {
  readonly link_grid_title?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyMediaMarquee = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyMediaMarqueePrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyMediaMarqueeFields>>;
};

export type LandingPageGroupBodyMediaMarqueeFields = {
  readonly feature_image?: Maybe<Scalars['Json']>;
  readonly feature_title?: Maybe<Scalars['String']>;
  readonly feature_description?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyMediaMarqueePrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
  readonly media_marquee_description?: Maybe<Scalars['Json']>;
  readonly video_url?: Maybe<Scalars['Json']>;
  readonly footer?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyNearbyLocations = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyNearbyLocationsPrimary>;
};

export type LandingPageGroupBodyNearbyLocationsPrimary = {
  readonly locations_title?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyNestedContent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyNestedContentPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyNestedContentFields>>;
};

export type LandingPageGroupBodyNestedContentFields = {
  readonly header?: Maybe<Scalars['String']>;
  readonly content?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyNestedContentPrimary = {
  readonly width?: Maybe<Scalars['String']>;
  readonly is_faq?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly title_alignment?: Maybe<Scalars['String']>;
  readonly expand_first_item?: Maybe<Scalars['Boolean']>;
  readonly content_layout?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyNewsletterSignup = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyNewsletterSignupPrimary>;
};

export type LandingPageGroupBodyNewsletterSignupPrimary = {
  readonly description?: Maybe<Scalars['String']>;
  readonly cta_button_text?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyPullQuote = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyPullQuotePrimary>;
};

export type LandingPageGroupBodyPullQuotePrimary = {
  readonly quote?: Maybe<Scalars['String']>;
  readonly attribution_name?: Maybe<Scalars['String']>;
  readonly attribution_title?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyQueriedResourcePosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyQueriedResourcePostsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyQueriedResourcePostsFields>>;
};

export type LandingPageGroupBodyQueriedResourcePostsFields = {
  readonly content_category_filter?: Maybe<Linkable>;
  readonly author_filter?: Maybe<Linkable>;
  readonly hide_author_name?: Maybe<Scalars['Boolean']>;
};

export type LandingPageGroupBodyQueriedResourcePostsPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly publication_date_sort_order?: Maybe<Scalars['String']>;
  readonly number_of_posts?: Maybe<Scalars['String']>;
  readonly content_type_filter?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
};

export type LandingPageGroupBodyQuoteUploadComponent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyQuoteUploadComponentPrimary>;
};

export type LandingPageGroupBodyQuoteUploadComponentPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly upload_component_heading?: Maybe<Scalars['String']>;
  readonly upload_component_cta?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyRepeatableRichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyRepeatableRichTextPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyRepeatableRichTextFields>>;
};

export type LandingPageGroupBodyRepeatableRichTextFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyRepeatableRichTextPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyResourcesPosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyResourcesPostsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyResourcesPostsFields>>;
};

export type LandingPageGroupBodyResourcesPostsFields = {
  readonly content_post?: Maybe<Linkable>;
};

export type LandingPageGroupBodyResourcesPostsPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly button_text?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyReusableCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyReusableCtaPrimary>;
};

export type LandingPageGroupBodyReusableCtaPrimary = {
  readonly link_to_document?: Maybe<Linkable>;
};

export type LandingPageGroupBodyRichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyRichTextPrimary>;
};

export type LandingPageGroupBodyRichTextPrimary = {
  readonly highlight_section?: Maybe<Scalars['Boolean']>;
  readonly title1?: Maybe<Scalars['String']>;
  readonly rich_text?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodySectionDivider = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodySectionDividerPrimary>;
};

export type LandingPageGroupBodySectionDividerPrimary = {
  readonly transition?: Maybe<Scalars['String']>;
  readonly illustration?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodySimpleInlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodySimpleInlineCtaPrimary>;
};

export type LandingPageGroupBodySimpleInlineCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
};

export type LandingPageGroupBodyTable = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyTablePrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyTableFields>>;
};

export type LandingPageGroupBodyTableFields = {
  readonly column_1_entry?: Maybe<Scalars['Json']>;
  readonly column_2_entry?: Maybe<Scalars['Json']>;
  readonly column_3_entry?: Maybe<Scalars['Json']>;
  readonly column_4_entry?: Maybe<Scalars['Json']>;
  readonly column_5_entry?: Maybe<Scalars['Json']>;
  readonly column_6_entry?: Maybe<Scalars['Json']>;
  readonly column_7_entry?: Maybe<Scalars['Json']>;
  readonly column_8_entry?: Maybe<Scalars['Json']>;
  readonly column_9_entry?: Maybe<Scalars['Json']>;
  readonly column_10_entry?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyTablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption_rich_text?: Maybe<Scalars['Json']>;
  readonly column_1_header?: Maybe<Scalars['String']>;
  readonly column_2_header?: Maybe<Scalars['String']>;
  readonly column_3_header?: Maybe<Scalars['String']>;
  readonly column_4_header?: Maybe<Scalars['String']>;
  readonly column_5_header?: Maybe<Scalars['String']>;
  readonly column_6_header?: Maybe<Scalars['String']>;
  readonly column_7_header?: Maybe<Scalars['String']>;
  readonly column_8_header?: Maybe<Scalars['String']>;
  readonly column_9_header?: Maybe<Scalars['String']>;
  readonly column_10_header?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyTestimonial = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyTestimonialPrimary>;
};

export type LandingPageGroupBodyTestimonialPrimary = {
  readonly quote?: Maybe<Scalars['String']>;
  readonly person_name?: Maybe<Scalars['String']>;
  readonly person_title?: Maybe<Scalars['String']>;
  readonly person_image?: Maybe<Scalars['Json']>;
  readonly company_image?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyTestimonials = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyTestimonialsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyTestimonialsFields>>;
};

export type LandingPageGroupBodyTestimonialsFields = {
  readonly person_image?: Maybe<Scalars['Json']>;
  readonly company_image?: Maybe<Scalars['Json']>;
  readonly quote?: Maybe<Scalars['String']>;
  readonly person_name?: Maybe<Scalars['String']>;
  readonly person_title?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyTestimonialsPrimary = {
  readonly title?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyTextAndImage = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyTextAndImagePrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyTextAndImageFields>>;
};

export type LandingPageGroupBodyTextAndImageFields = {
  readonly bullet_point?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyTextAndImagePrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly display_new_badge?: Maybe<Scalars['Boolean']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyTextAndImageRows = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyTextAndImageRowsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyTextAndImageRowsFields>>;
};

export type LandingPageGroupBodyTextAndImageRowsFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyTextAndImageRowsPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyTextAndImageV2 = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyTextAndImageV2Primary>;
};

export type LandingPageGroupBodyTextAndImageV2Primary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly use_image_as_background?: Maybe<Scalars['Boolean']>;
  readonly image_background_alignment?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyTextAndModelFile = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyTextAndModelFilePrimary>;
  readonly fields?: Maybe<ReadonlyArray<LandingPageGroupBodyTextAndModelFileFields>>;
};

export type LandingPageGroupBodyTextAndModelFileFields = {
  readonly bullet_point?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyTextAndModelFilePrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly display_new_badge?: Maybe<Scalars['Boolean']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly model_file?: Maybe<Linkable>;
  readonly cta_text?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyThreeSimpleCards = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyThreeSimpleCardsPrimary>;
};

export type LandingPageGroupBodyThreeSimpleCardsPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly card_1_image?: Maybe<Scalars['Json']>;
  readonly card_1_title?: Maybe<Scalars['String']>;
  readonly card_1_description?: Maybe<Scalars['Json']>;
  readonly card_2_image?: Maybe<Scalars['Json']>;
  readonly card_2_title?: Maybe<Scalars['String']>;
  readonly card_2_description?: Maybe<Scalars['Json']>;
  readonly card_3_image?: Maybe<Scalars['Json']>;
  readonly card_3_title?: Maybe<Scalars['String']>;
  readonly card_3_description?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyThreeSimpleCircles = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyThreeSimpleCirclesPrimary>;
};

export type LandingPageGroupBodyThreeSimpleCirclesPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly circle_1_image?: Maybe<Scalars['Json']>;
  readonly circle_1_title?: Maybe<Scalars['String']>;
  readonly circle_1_description?: Maybe<Scalars['Json']>;
  readonly circle_2_image?: Maybe<Scalars['Json']>;
  readonly circle_2_title?: Maybe<Scalars['String']>;
  readonly circle_2_description?: Maybe<Scalars['Json']>;
  readonly circle_3_image?: Maybe<Scalars['Json']>;
  readonly circle_3_title?: Maybe<Scalars['String']>;
  readonly circle_3_description?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyVideoCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyVideoCtaPrimary>;
};

export type LandingPageGroupBodyVideoCtaPrimary = {
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly video_url?: Maybe<Scalars['Json']>;
};

export type LandingPageGroupBodyXometryEverywhere = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
};

export type LandingPageGroupBodyYoutubeVideo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupBodyYoutubeVideoPrimary>;
};

export type LandingPageGroupBodyYoutubeVideoPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['Json']>;
};

/** A connection to a list of items. */
export type LandingPageGroupConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<LandingPageGroupConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type LandingPageGroupConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: LandingPageGroup;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type LandingPageGroupProcesses = {
  readonly process_name?: Maybe<Scalars['String']>;
  readonly capability?: Maybe<Linkable>;
};

export type LandingPageGroupSeo = LandingPageGroupSeoSeo;

export type LandingPageGroupSeoSeo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<LandingPageGroupSeoSeoPrimary>;
};

export type LandingPageGroupSeoSeoPrimary = {
  readonly meta_title?: Maybe<Scalars['String']>;
  readonly meta_description?: Maybe<Scalars['String']>;
};


export type MainMenu = Document & Linkable & {
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type MainMenuConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<MainMenuConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type MainMenuConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: MainMenu;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type MarketplaceRequestStartLeftContent = Document & Linkable & {
  readonly title?: Maybe<Scalars['String']>;
  readonly large_text_paragraph?: Maybe<Scalars['Json']>;
  readonly call_outs?: Maybe<ReadonlyArray<MarketplaceRequestStartLeftContentCallOuts>>;
  readonly small_text_paragraph?: Maybe<Scalars['Json']>;
  readonly video_links?: Maybe<ReadonlyArray<MarketplaceRequestStartLeftContentVideoLinks>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type MarketplaceRequestStartLeftContentCallOuts = {
  readonly icon?: Maybe<Scalars['String']>;
  readonly call_out_text?: Maybe<Scalars['Json']>;
};

/** A connection to a list of items. */
export type MarketplaceRequestStartLeftContentConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<MarketplaceRequestStartLeftContentConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type MarketplaceRequestStartLeftContentConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: MarketplaceRequestStartLeftContent;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type MarketplaceRequestStartLeftContentVideoLinks = {
  readonly link_text?: Maybe<Scalars['String']>;
  readonly link_destination?: Maybe<Linkable>;
};

export type Material = Document & Linkable & {
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type MaterialConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<MaterialConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type MaterialConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: Material;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type MaterialType = Document & Linkable & {
  readonly material_id?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
  readonly applications?: Maybe<Scalars['Json']>;
  readonly images?: Maybe<ReadonlyArray<MaterialTypeImages>>;
  readonly material_category?: Maybe<Scalars['String']>;
  readonly spec_sheet?: Maybe<Linkable>;
  readonly process?: Maybe<Scalars['String']>;
  readonly subprocess?: Maybe<Scalars['String']>;
  readonly cost?: Maybe<Scalars['String']>;
  readonly attributes?: Maybe<ReadonlyArray<MaterialTypeAttributes>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type MaterialTypeAttributes = {
  readonly attribute?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type MaterialTypeConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<MaterialTypeConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type MaterialTypeConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: MaterialType;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type MaterialTypeImages = {
  readonly image?: Maybe<Scalars['Json']>;
};

export type Materials = Document & Linkable & {
  readonly title?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
  readonly body?: Maybe<ReadonlyArray<MaterialsBody>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type MaterialsBody = MaterialsBodySeo | MaterialsBodyPageLocales;

export type MaterialsBodyPageLocales = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<MaterialsBodyPageLocalesFields>>;
};

export type MaterialsBodyPageLocalesFields = {
  readonly locale?: Maybe<Scalars['String']>;
  readonly page?: Maybe<Linkable>;
};

export type MaterialsBodySeo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<MaterialsBodySeoPrimary>;
};

export type MaterialsBodySeoPrimary = {
  readonly meta_title?: Maybe<Scalars['String']>;
  readonly meta_description?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type MaterialsConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<MaterialsConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type MaterialsConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: Materials;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type Meta = {
  /** The id of the document. */
  readonly id: Scalars['String'];
  /** The uid of the document. */
  readonly uid?: Maybe<Scalars['String']>;
  /** The type of the document. */
  readonly type: Scalars['String'];
  /** The tags of the document. */
  readonly tags: ReadonlyArray<Scalars['String']>;
  /** The language of the document. */
  readonly lang: Scalars['String'];
  /** Alternate languages the document. */
  readonly alternateLanguages: ReadonlyArray<RelatedDocument>;
  /** The first publication date of the document. */
  readonly firstPublicationDate?: Maybe<Scalars['DateTime']>;
  /** The last publication date of the document. */
  readonly lastPublicationDate?: Maybe<Scalars['DateTime']>;
};

export type Nav = Document & Linkable & {
  readonly nav_title?: Maybe<Scalars['String']>;
  readonly body?: Maybe<ReadonlyArray<NavBody>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type NavBody = NavBodySubNavLinks | NavBodyNavLink;

export type NavBodyNavLink = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<NavBodyNavLinkPrimary>;
};

export type NavBodyNavLinkPrimary = {
  readonly nav_link_text?: Maybe<Scalars['String']>;
  readonly nav_link?: Maybe<Linkable>;
  readonly icon?: Maybe<Scalars['Json']>;
};

export type NavBodySubNavLinks = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<NavBodySubNavLinksPrimary>;
};

export type NavBodySubNavLinksPrimary = {
  readonly sub_nav_links_text?: Maybe<Scalars['String']>;
  readonly sub_nav_links?: Maybe<Linkable>;
  readonly icon?: Maybe<Scalars['Json']>;
};

/** A connection to a list of items. */
export type NavConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<NavConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type NavConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: Nav;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type Onboarding = Document & Linkable & {
  readonly title?: Maybe<Scalars['Json']>;
  readonly subtext?: Maybe<Scalars['Json']>;
  readonly benefit_image_one?: Maybe<Scalars['Json']>;
  readonly benefit_text_one?: Maybe<Scalars['Json']>;
  readonly benefit_image_two?: Maybe<Scalars['Json']>;
  readonly benefit_text_two?: Maybe<Scalars['Json']>;
  readonly benefit_image_three?: Maybe<Scalars['Json']>;
  readonly benefit_text_three?: Maybe<Scalars['Json']>;
  readonly splash_image?: Maybe<Scalars['Json']>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type OnboardingConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<OnboardingConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type OnboardingConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: Onboarding;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor?: Maybe<Scalars['String']>;
};

export type PartnerToolkitBanner = Document & Linkable & {
  readonly title?: Maybe<Scalars['String']>;
  readonly subtext?: Maybe<Scalars['Json']>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly link?: Maybe<Linkable>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type PartnerToolkitBannerConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<PartnerToolkitBannerConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type PartnerToolkitBannerConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: PartnerToolkitBanner;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type PersonalizedBanner = Document & Linkable & {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['String']>;
  readonly body?: Maybe<Scalars['Json']>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_url?: Maybe<Linkable>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type PersonalizedBannerConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<PersonalizedBannerConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type PersonalizedBannerConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: PersonalizedBanner;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type PersonalizedStartPageMarketingCards = Document & Linkable & {
  readonly body?: Maybe<ReadonlyArray<PersonalizedStartPageMarketingCardsBody>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type PersonalizedStartPageMarketingCardsBody = PersonalizedStartPageMarketingCardsBodyPersonalizedStartPageMarketingCard;

export type PersonalizedStartPageMarketingCardsBodyPersonalizedStartPageMarketingCard = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<PersonalizedStartPageMarketingCardsBodyPersonalizedStartPageMarketingCardPrimary>;
};

export type PersonalizedStartPageMarketingCardsBodyPersonalizedStartPageMarketingCardPrimary = {
  readonly card_id?: Maybe<Scalars['String']>;
  readonly default_content?: Maybe<Scalars['Boolean']>;
  readonly new_label?: Maybe<Scalars['Boolean']>;
  readonly header_title?: Maybe<Scalars['String']>;
  readonly split_name?: Maybe<Scalars['String']>;
  readonly header_subtitle?: Maybe<Scalars['String']>;
  readonly header_link_text?: Maybe<Scalars['String']>;
  readonly header_link_url?: Maybe<Linkable>;
  readonly header_image?: Maybe<Scalars['Json']>;
  readonly card_body?: Maybe<Scalars['Json']>;
  readonly body_image?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_url?: Maybe<Linkable>;
};

/** A connection to a list of items. */
export type PersonalizedStartPageMarketingCardsConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<PersonalizedStartPageMarketingCardsConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type PersonalizedStartPageMarketingCardsConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: PersonalizedStartPageMarketingCards;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type Query = {
  readonly _allDocuments: DocumentConnection;
  readonly allJob_confirmation_bannerss: JobConfirmationBannersConnectionConnection;
  readonly allMain_menus: MainMenuConnectionConnection;
  readonly allPartner_toolkit_banners: PartnerToolkitBannerConnectionConnection;
  readonly header_links?: Maybe<HeaderLinks>;
  readonly allHeader_linkss: HeaderLinksConnectionConnection;
  readonly allOnboardings: OnboardingConnectionConnection;
  readonly content_category?: Maybe<ContentCategory>;
  readonly allContent_categorys: ContentCategoryConnectionConnection;
  readonly standard_landing_page?: Maybe<StandardLandingPage>;
  readonly allStandard_landing_pages: StandardLandingPageConnectionConnection;
  readonly header_ctas?: Maybe<HeaderCtas>;
  readonly allHeader_ctass: HeaderCtasConnectionConnection;
  readonly allRedirectss: RedirectsConnectionConnection;
  readonly allFooters: FooterConnectionConnection;
  readonly go_green?: Maybe<GoGreen>;
  readonly allGo_greens: GoGreenConnectionConnection;
  readonly allAuthors: AuthorConnectionConnection;
  readonly content_post?: Maybe<ContentPost>;
  readonly allContent_posts: ContentPostConnectionConnection;
  readonly self_serve_subscription?: Maybe<SelfServeSubscription>;
  readonly allSelf_serve_subscriptions: SelfServeSubscriptionConnectionConnection;
  readonly allCta_contents: CtaContentConnectionConnection;
  readonly personalized_banner?: Maybe<PersonalizedBanner>;
  readonly allPersonalized_banners: PersonalizedBannerConnectionConnection;
  readonly allContent_hubs: ContentHubConnectionConnection;
  readonly nav?: Maybe<Nav>;
  readonly allNavs: NavConnectionConnection;
  readonly materials?: Maybe<Materials>;
  readonly allMaterialss: MaterialsConnectionConnection;
  readonly allLanding_page_groups: LandingPageGroupConnectionConnection;
  readonly allAnnouncement_banners: AnnouncementBannerConnectionConnection;
  readonly allPersonalized_start_page_marketing_cardss: PersonalizedStartPageMarketingCardsConnectionConnection;
  readonly allHomes: HomeConnectionConnection;
  readonly short_landing_page?: Maybe<ShortLandingPage>;
  readonly allShort_landing_pages: ShortLandingPageConnectionConnection;
  readonly capability?: Maybe<Capability>;
  readonly allCapabilitys: CapabilityConnectionConnection;
  readonly allMarketplace_request_start_left_contents: MarketplaceRequestStartLeftContentConnectionConnection;
  readonly material_type?: Maybe<MaterialType>;
  readonly allMaterial_types: MaterialTypeConnectionConnection;
  readonly allHeaders: HeaderConnectionConnection;
  readonly allMaterials: MaterialConnectionConnection;
  readonly ultimate_guide?: Maybe<UltimateGuide>;
  readonly allUltimate_guides: UltimateGuideConnectionConnection;
};


export type QueryAllDocumentsArgs = {
  sortBy?: Maybe<SortDocumentsBy>;
  type?: Maybe<Scalars['String']>;
  type_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllJobConfirmationBannerssArgs = {
  sortBy?: Maybe<SortJobConfirmationBannersy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereJobConfirmationBanners>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllMainMenusArgs = {
  sortBy?: Maybe<SortMainMenuy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllPartnerToolkitBannersArgs = {
  sortBy?: Maybe<SortPartnerToolkitBannery>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WherePartnerToolkitBanner>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryHeaderLinksArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllHeaderLinkssArgs = {
  sortBy?: Maybe<SortHeaderLinksy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereHeaderLinks>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllOnboardingsArgs = {
  sortBy?: Maybe<SortOnboardingy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereOnboarding>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryContentCategoryArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllContentCategorysArgs = {
  sortBy?: Maybe<SortContentCategoryy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereContentCategory>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryStandardLandingPageArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllStandardLandingPagesArgs = {
  sortBy?: Maybe<SortStandardLandingPagey>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereStandardLandingPage>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryHeaderCtasArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllHeaderCtassArgs = {
  sortBy?: Maybe<SortHeaderCtasy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereHeaderCtas>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllRedirectssArgs = {
  sortBy?: Maybe<SortRedirectsy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereRedirects>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllFootersArgs = {
  sortBy?: Maybe<SortFootery>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereFooter>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryGoGreenArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllGoGreensArgs = {
  sortBy?: Maybe<SortGoGreeny>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereGoGreen>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllAuthorsArgs = {
  sortBy?: Maybe<SortAuthory>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereAuthor>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryContentPostArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllContentPostsArgs = {
  sortBy?: Maybe<SortContentPosty>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereContentPost>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySelfServeSubscriptionArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllSelfServeSubscriptionsArgs = {
  sortBy?: Maybe<SortSelfServeSubscriptiony>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereSelfServeSubscription>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllCtaContentsArgs = {
  sortBy?: Maybe<SortCtaContenty>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereCtaContent>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPersonalizedBannerArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllPersonalizedBannersArgs = {
  sortBy?: Maybe<SortPersonalizedBannery>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WherePersonalizedBanner>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllContentHubsArgs = {
  sortBy?: Maybe<SortContentHuby>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereContentHub>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryNavArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllNavsArgs = {
  sortBy?: Maybe<SortNavy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereNav>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMaterialsArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllMaterialssArgs = {
  sortBy?: Maybe<SortMaterialsy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereMaterials>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllLandingPageGroupsArgs = {
  sortBy?: Maybe<SortLandingPageGroupy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereLandingPageGroup>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllAnnouncementBannersArgs = {
  sortBy?: Maybe<SortAnnouncementBannery>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereAnnouncementBanner>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllPersonalizedStartPageMarketingCardssArgs = {
  sortBy?: Maybe<SortPersonalizedStartPageMarketingCardsy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllHomesArgs = {
  sortBy?: Maybe<SortHomey>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryShortLandingPageArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllShortLandingPagesArgs = {
  sortBy?: Maybe<SortShortLandingPagey>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCapabilityArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllCapabilitysArgs = {
  sortBy?: Maybe<SortCapabilityy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereCapability>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketplaceRequestStartLeftContentsArgs = {
  sortBy?: Maybe<SortMarketplaceRequestStartLeftContenty>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereMarketplaceRequestStartLeftContent>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryMaterialTypeArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllMaterialTypesArgs = {
  sortBy?: Maybe<SortMaterialTypey>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereMaterialType>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllHeadersArgs = {
  sortBy?: Maybe<SortHeadery>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereHeader>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryAllMaterialsArgs = {
  sortBy?: Maybe<SortMaterialy>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryUltimateGuideArgs = {
  uid: Scalars['String'];
  lang: Scalars['String'];
};


export type QueryAllUltimateGuidesArgs = {
  sortBy?: Maybe<SortUltimateGuidey>;
  uid?: Maybe<Scalars['String']>;
  uid_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  id?: Maybe<Scalars['String']>;
  id_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
  tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  tags_in?: Maybe<ReadonlyArray<Scalars['String']>>;
  firstPublicationDate?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_after?: Maybe<Scalars['DateTime']>;
  firstPublicationDate_before?: Maybe<Scalars['DateTime']>;
  lastPublicationDate?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_after?: Maybe<Scalars['DateTime']>;
  lastPublicationDate_before?: Maybe<Scalars['DateTime']>;
  fulltext?: Maybe<Scalars['String']>;
  similar?: Maybe<Similar>;
  where?: Maybe<WhereUltimateGuide>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Redirects = Document & Linkable & {
  readonly redirect?: Maybe<ReadonlyArray<RedirectsRedirect>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type RedirectsConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<RedirectsConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type RedirectsConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: Redirects;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type RedirectsRedirect = {
  readonly old_url?: Maybe<Scalars['String']>;
  readonly new_url?: Maybe<Scalars['String']>;
};

export type RelatedDocument = {
  /** The id of the document. */
  readonly id: Scalars['String'];
  /** The uid of the document. */
  readonly uid?: Maybe<Scalars['String']>;
  /** The type of the document. */
  readonly type: Scalars['String'];
  /** The language of the document. */
  readonly lang: Scalars['String'];
};

export type SelfServeSubscription = Document & Linkable & {
  readonly benefit_one?: Maybe<Scalars['Json']>;
  readonly benefit_two?: Maybe<Scalars['Json']>;
  readonly benefit_three?: Maybe<Scalars['Json']>;
  readonly benefit_four?: Maybe<Scalars['Json']>;
  readonly benefit_five?: Maybe<Scalars['Json']>;
  readonly benefit_six?: Maybe<Scalars['Json']>;
  readonly benefit_seven?: Maybe<Scalars['Json']>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type SelfServeSubscriptionConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<SelfServeSubscriptionConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type SelfServeSubscriptionConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: SelfServeSubscription;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type ShortLandingPage = Document & Linkable & {
  readonly body?: Maybe<ReadonlyArray<ShortLandingPageBody>>;
  readonly body1?: Maybe<ReadonlyArray<ShortLandingPageBody1>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBody = ShortLandingPageBodyThreeSimpleCards | ShortLandingPageBodyTestimonial | ShortLandingPageBodyNewsletterSignup | ShortLandingPageBodyLandingPageHero1 | ShortLandingPageBodyTextAndImage | ShortLandingPageBodyNestedContent | ShortLandingPageBodyRichText | ShortLandingPageBodyCenteredHero | ShortLandingPageBodyImageAndList | ShortLandingPageBodyFullWidthCta | ShortLandingPageBodyCenteredFullWidthCta | ShortLandingPageBodyTable | ShortLandingPageBodyFormHero | ShortLandingPageBodyImageGrid | ShortLandingPageBodyAdvancedTable | ShortLandingPageBodyQueriedResourcePosts | ShortLandingPageBodyImageAndCaption | ShortLandingPageBodyBenefitQuadrant | ShortLandingPageBodyCapabilityList | ShortLandingPageBodyCarbonOffset | ShortLandingPageBodyYoutubeVideo | ShortLandingPageBodyFeaturedCustomers | ShortLandingPageBodyCountUp | ShortLandingPageBodyPullQuote | ShortLandingPageBodyContentPost | ShortLandingPageBodyMediaMarquee | ShortLandingPageBodyImageCarousel | ShortLandingPageBodyLinkGrid | ShortLandingPageBodyInlineCta | ShortLandingPageBodyThreeSimpleCircles | ShortLandingPageBodyXometryEverywhere | ShortLandingPageBodySimpleInlineCta | ShortLandingPageBodyTestimonials | ShortLandingPageBodyResourcesPosts | ShortLandingPageBodyTextAndModelFile | ShortLandingPageBodyQuoteUploadComponent | ShortLandingPageBodyCustomerRfqCta | ShortLandingPageBodyLatestCapabilities | ShortLandingPageBodyVideoCta | ShortLandingPageBodyImageCta | ShortLandingPageBodyTextAndImageRows | ShortLandingPageBodyReusableCta | ShortLandingPageBodyRepeatableRichText | ShortLandingPageBodyTextAndImageV2 | ShortLandingPageBodySectionDivider | ShortLandingPageBodyAdvancedSlice | ShortLandingPageBodyHeroAuthor;

export type ShortLandingPageBody1 = ShortLandingPageBody1Seo | ShortLandingPageBody1PageLocales;

export type ShortLandingPageBody1PageLocales = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBody1PageLocalesFields>>;
};

export type ShortLandingPageBody1PageLocalesFields = {
  readonly locale?: Maybe<Scalars['String']>;
  readonly page?: Maybe<Linkable>;
};

export type ShortLandingPageBody1Seo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBody1SeoPrimary>;
};

export type ShortLandingPageBody1SeoPrimary = {
  readonly meta_title?: Maybe<Scalars['String']>;
  readonly meta_description?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyAdvancedSlice = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyAdvancedSlicePrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyAdvancedSliceFields>>;
};

export type ShortLandingPageBodyAdvancedSliceFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
  readonly content_post?: Maybe<Linkable>;
};

export type ShortLandingPageBodyAdvancedSlicePrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly text_and_image_alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly use_image_as_background?: Maybe<Scalars['Boolean']>;
  readonly image_background_alignment?: Maybe<Scalars['String']>;
  readonly repeatable_layout?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyAdvancedTable = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyAdvancedTablePrimary>;
};

export type ShortLandingPageBodyAdvancedTablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption?: Maybe<Scalars['Json']>;
  readonly csv_file?: Maybe<Linkable>;
};

export type ShortLandingPageBodyBenefitQuadrant = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyBenefitQuadrantPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyBenefitQuadrantFields>>;
};

export type ShortLandingPageBodyBenefitQuadrantFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyBenefitQuadrantPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['Json']>;
  readonly footer?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyCapabilityList = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyCapabilityListPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyCapabilityListFields>>;
};

export type ShortLandingPageBodyCapabilityListFields = {
  readonly capability?: Maybe<Linkable>;
};

export type ShortLandingPageBodyCapabilityListPrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyCarbonOffset = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyCarbonOffsetPrimary>;
};

export type ShortLandingPageBodyCarbonOffsetPrimary = {
  readonly header?: Maybe<Scalars['Json']>;
  readonly text?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyCenteredFullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyCenteredFullWidthCtaPrimary>;
};

export type ShortLandingPageBodyCenteredFullWidthCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
};

export type ShortLandingPageBodyCenteredHero = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyCenteredHeroPrimary>;
};

export type ShortLandingPageBodyCenteredHeroPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_microcopy?: Maybe<Scalars['Boolean']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyContentPost = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyContentPostPrimary>;
};

export type ShortLandingPageBodyContentPostPrimary = {
  readonly content_post?: Maybe<Linkable>;
  readonly content_icon?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyCountUp = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyCountUpPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyCountUpFields>>;
};

export type ShortLandingPageBodyCountUpFields = {
  readonly category_image?: Maybe<Scalars['Json']>;
  readonly category_title?: Maybe<Scalars['String']>;
  readonly number?: Maybe<Scalars['Float']>;
  readonly suffix?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyCountUpPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly count_up_subtitle?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyCustomerRfqCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyCustomerRfqCtaPrimary>;
};

export type ShortLandingPageBodyCustomerRfqCtaPrimary = {
  readonly customer_rfq_cta_subtitle?: Maybe<Scalars['Json']>;
  readonly customer_rfq_cta_button_text?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyFeaturedCustomers = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyFeaturedCustomersPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyFeaturedCustomersFields>>;
};

export type ShortLandingPageBodyFeaturedCustomersFields = {
  readonly company_logo?: Maybe<Scalars['Json']>;
  readonly company_link?: Maybe<Linkable>;
};

export type ShortLandingPageBodyFeaturedCustomersPrimary = {
  readonly subtitle?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyFormHero = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyFormHeroPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyFormHeroFields>>;
};

export type ShortLandingPageBodyFormHeroFields = {
  readonly quick_fact_text?: Maybe<Scalars['Json']>;
  readonly quick_fact_icon?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyFormHeroPrimary = {
  readonly single_column?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly form_title?: Maybe<Scalars['String']>;
  readonly form_subtitle?: Maybe<Scalars['Json']>;
  readonly hubspot_form_id?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyFullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyFullWidthCtaPrimary>;
};

export type ShortLandingPageBodyFullWidthCtaPrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly fine_print?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyHeroAuthor = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyHeroAuthorPrimary>;
};

export type ShortLandingPageBodyHeroAuthorPrimary = {
  readonly author?: Maybe<Linkable>;
};

export type ShortLandingPageBodyImageAndList = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyImageAndListPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyImageAndListFields>>;
};

export type ShortLandingPageBodyImageAndListFields = {
  readonly image1?: Maybe<Scalars['Json']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyImageAndListPrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyImageAndCaption = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyImageAndCaptionPrimary>;
};

export type ShortLandingPageBodyImageAndCaptionPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly caption?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyImageCarousel = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyImageCarouselPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyImageCarouselFields>>;
};

export type ShortLandingPageBodyImageCarouselFields = {
  readonly image1?: Maybe<Scalars['Json']>;
  readonly image_caption?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyImageCarouselPrimary = {
  readonly primary_caption?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyImageCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyImageCtaPrimary>;
};

export type ShortLandingPageBodyImageCtaPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyImageGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyImageGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyImageGridFields>>;
};

export type ShortLandingPageBodyImageGridFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyImageGridPrimary = {
  readonly columns?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyInlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyInlineCtaPrimary>;
};

export type ShortLandingPageBodyInlineCtaPrimary = {
  readonly copy?: Maybe<Scalars['String']>;
  readonly cta_text1?: Maybe<Scalars['String']>;
  readonly cta_link1?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyLandingPageHero1 = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyLandingPageHero1Primary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyLandingPageHero1Fields>>;
};

export type ShortLandingPageBodyLandingPageHero1Fields = {
  readonly quick_fact_text?: Maybe<Scalars['Json']>;
  readonly quick_fact_icon?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyLandingPageHero1Primary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_microcopy?: Maybe<Scalars['Boolean']>;
  readonly rfq_cta_type?: Maybe<Scalars['String']>;
  readonly rfq_cta_section_title?: Maybe<Scalars['String']>;
  readonly customer_rfq_prompt_text?: Maybe<Scalars['String']>;
  readonly customer_rfq_prompt_button_text?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyLatestCapabilities = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyLatestCapabilitiesPrimary>;
};

export type ShortLandingPageBodyLatestCapabilitiesPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly publication_date_sort_order?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly number_of_post?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyLinkGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyLinkGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyLinkGridFields>>;
};

export type ShortLandingPageBodyLinkGridFields = {
  readonly link_text?: Maybe<Scalars['String']>;
  readonly link?: Maybe<Linkable>;
};

export type ShortLandingPageBodyLinkGridPrimary = {
  readonly link_grid_title?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyMediaMarquee = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyMediaMarqueePrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyMediaMarqueeFields>>;
};

export type ShortLandingPageBodyMediaMarqueeFields = {
  readonly feature_image?: Maybe<Scalars['Json']>;
  readonly feature_title?: Maybe<Scalars['String']>;
  readonly feature_description?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyMediaMarqueePrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly media_marquee_description?: Maybe<Scalars['Json']>;
  readonly video_url?: Maybe<Scalars['Json']>;
  readonly footer?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyNestedContent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyNestedContentPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyNestedContentFields>>;
};

export type ShortLandingPageBodyNestedContentFields = {
  readonly header?: Maybe<Scalars['String']>;
  readonly content?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyNestedContentPrimary = {
  readonly width?: Maybe<Scalars['String']>;
  readonly is_faq?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly title_alignment?: Maybe<Scalars['String']>;
  readonly expand_first_item?: Maybe<Scalars['Boolean']>;
  readonly content_layout?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyNewsletterSignup = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyNewsletterSignupPrimary>;
};

export type ShortLandingPageBodyNewsletterSignupPrimary = {
  readonly description?: Maybe<Scalars['String']>;
  readonly cta_button_text?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyPullQuote = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyPullQuotePrimary>;
};

export type ShortLandingPageBodyPullQuotePrimary = {
  readonly quote?: Maybe<Scalars['String']>;
  readonly attribution_name?: Maybe<Scalars['String']>;
  readonly attribution_title?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyQueriedResourcePosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyQueriedResourcePostsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyQueriedResourcePostsFields>>;
};

export type ShortLandingPageBodyQueriedResourcePostsFields = {
  readonly content_category_filter?: Maybe<Linkable>;
  readonly author_filter?: Maybe<Linkable>;
  readonly hide_author_name?: Maybe<Scalars['Boolean']>;
};

export type ShortLandingPageBodyQueriedResourcePostsPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly publication_date_sort_order?: Maybe<Scalars['String']>;
  readonly number_of_posts?: Maybe<Scalars['String']>;
  readonly content_type_filter?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
};

export type ShortLandingPageBodyQuoteUploadComponent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyQuoteUploadComponentPrimary>;
};

export type ShortLandingPageBodyQuoteUploadComponentPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly upload_component_heading?: Maybe<Scalars['String']>;
  readonly upload_component_cta?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyRepeatableRichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyRepeatableRichTextPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyRepeatableRichTextFields>>;
};

export type ShortLandingPageBodyRepeatableRichTextFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyRepeatableRichTextPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyResourcesPosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyResourcesPostsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyResourcesPostsFields>>;
};

export type ShortLandingPageBodyResourcesPostsFields = {
  readonly content_post?: Maybe<Linkable>;
};

export type ShortLandingPageBodyResourcesPostsPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly button_text?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyReusableCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyReusableCtaPrimary>;
};

export type ShortLandingPageBodyReusableCtaPrimary = {
  readonly link_to_document?: Maybe<Linkable>;
};

export type ShortLandingPageBodyRichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyRichTextPrimary>;
};

export type ShortLandingPageBodyRichTextPrimary = {
  readonly highlight_section?: Maybe<Scalars['Boolean']>;
  readonly title1?: Maybe<Scalars['String']>;
  readonly rich_text?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodySectionDivider = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodySectionDividerPrimary>;
};

export type ShortLandingPageBodySectionDividerPrimary = {
  readonly transition?: Maybe<Scalars['String']>;
  readonly illustration?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodySimpleInlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodySimpleInlineCtaPrimary>;
};

export type ShortLandingPageBodySimpleInlineCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
};

export type ShortLandingPageBodyTable = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyTablePrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyTableFields>>;
};

export type ShortLandingPageBodyTableFields = {
  readonly column_1_entry?: Maybe<Scalars['Json']>;
  readonly column_2_entry?: Maybe<Scalars['Json']>;
  readonly column_3_entry?: Maybe<Scalars['Json']>;
  readonly column_4_entry?: Maybe<Scalars['Json']>;
  readonly column_5_entry?: Maybe<Scalars['Json']>;
  readonly column_6_entry?: Maybe<Scalars['Json']>;
  readonly column_7_entry?: Maybe<Scalars['Json']>;
  readonly column_8_entry?: Maybe<Scalars['Json']>;
  readonly column_9_entry?: Maybe<Scalars['Json']>;
  readonly column_10_entry?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyTablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption_rich_text?: Maybe<Scalars['Json']>;
  readonly column_1_header?: Maybe<Scalars['String']>;
  readonly column_2_header?: Maybe<Scalars['String']>;
  readonly column_3_header?: Maybe<Scalars['String']>;
  readonly column_4_header?: Maybe<Scalars['String']>;
  readonly column_5_header?: Maybe<Scalars['String']>;
  readonly column_6_header?: Maybe<Scalars['String']>;
  readonly column_7_header?: Maybe<Scalars['String']>;
  readonly column_8_header?: Maybe<Scalars['String']>;
  readonly column_9_header?: Maybe<Scalars['String']>;
  readonly column_10_header?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyTestimonial = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyTestimonialPrimary>;
};

export type ShortLandingPageBodyTestimonialPrimary = {
  readonly quote?: Maybe<Scalars['String']>;
  readonly person_name?: Maybe<Scalars['String']>;
  readonly person_title?: Maybe<Scalars['String']>;
  readonly person_image?: Maybe<Scalars['Json']>;
  readonly company_image?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyTestimonials = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyTestimonialsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyTestimonialsFields>>;
};

export type ShortLandingPageBodyTestimonialsFields = {
  readonly person_image?: Maybe<Scalars['Json']>;
  readonly company_image?: Maybe<Scalars['Json']>;
  readonly quote?: Maybe<Scalars['String']>;
  readonly person_name?: Maybe<Scalars['String']>;
  readonly person_title?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyTestimonialsPrimary = {
  readonly title?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyTextAndImage = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyTextAndImagePrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyTextAndImageFields>>;
};

export type ShortLandingPageBodyTextAndImageFields = {
  readonly bullet_point?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyTextAndImagePrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly display_new_badge?: Maybe<Scalars['Boolean']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyTextAndImageRows = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyTextAndImageRowsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyTextAndImageRowsFields>>;
};

export type ShortLandingPageBodyTextAndImageRowsFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyTextAndImageRowsPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyTextAndImageV2 = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyTextAndImageV2Primary>;
};

export type ShortLandingPageBodyTextAndImageV2Primary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly use_image_as_background?: Maybe<Scalars['Boolean']>;
  readonly image_background_alignment?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyTextAndModelFile = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyTextAndModelFilePrimary>;
  readonly fields?: Maybe<ReadonlyArray<ShortLandingPageBodyTextAndModelFileFields>>;
};

export type ShortLandingPageBodyTextAndModelFileFields = {
  readonly bullet_point?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyTextAndModelFilePrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly display_new_badge?: Maybe<Scalars['Boolean']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly model_file?: Maybe<Linkable>;
  readonly cta_text?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyThreeSimpleCards = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyThreeSimpleCardsPrimary>;
};

export type ShortLandingPageBodyThreeSimpleCardsPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly card_1_image?: Maybe<Scalars['Json']>;
  readonly card_1_title?: Maybe<Scalars['String']>;
  readonly card_1_description?: Maybe<Scalars['Json']>;
  readonly card_2_image?: Maybe<Scalars['Json']>;
  readonly card_2_title?: Maybe<Scalars['String']>;
  readonly card_2_description?: Maybe<Scalars['Json']>;
  readonly card_3_image?: Maybe<Scalars['Json']>;
  readonly card_3_title?: Maybe<Scalars['String']>;
  readonly card_3_description?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyThreeSimpleCircles = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyThreeSimpleCirclesPrimary>;
};

export type ShortLandingPageBodyThreeSimpleCirclesPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly circle_1_image?: Maybe<Scalars['Json']>;
  readonly circle_1_title?: Maybe<Scalars['String']>;
  readonly circle_1_description?: Maybe<Scalars['Json']>;
  readonly circle_2_image?: Maybe<Scalars['Json']>;
  readonly circle_2_title?: Maybe<Scalars['String']>;
  readonly circle_2_description?: Maybe<Scalars['Json']>;
  readonly circle_3_image?: Maybe<Scalars['Json']>;
  readonly circle_3_title?: Maybe<Scalars['String']>;
  readonly circle_3_description?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyVideoCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyVideoCtaPrimary>;
};

export type ShortLandingPageBodyVideoCtaPrimary = {
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly video_url?: Maybe<Scalars['Json']>;
};

export type ShortLandingPageBodyXometryEverywhere = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
};

export type ShortLandingPageBodyYoutubeVideo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<ShortLandingPageBodyYoutubeVideoPrimary>;
};

export type ShortLandingPageBodyYoutubeVideoPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['Json']>;
};

/** A connection to a list of items. */
export type ShortLandingPageConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<ShortLandingPageConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type ShortLandingPageConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: ShortLandingPage;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export enum SortAnnouncementBannery {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  CtaTextAsc = 'cta_text_ASC',
  CtaTextDesc = 'cta_text_DESC'
}

export enum SortAuthory {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  HonorificPrefixAsc = 'honorific_prefix_ASC',
  HonorificPrefixDesc = 'honorific_prefix_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  HonorificSuffixAsc = 'honorific_suffix_ASC',
  HonorificSuffixDesc = 'honorific_suffix_DESC',
  BioAsc = 'bio_ASC',
  BioDesc = 'bio_DESC'
}

export enum SortCapabilityy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortContentCategoryy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  CategoryTitleAsc = 'category_title_ASC',
  CategoryTitleDesc = 'category_title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC'
}

export enum SortContentHuby {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  ContentHubNameAsc = 'content_hub_name_ASC',
  ContentHubNameDesc = 'content_hub_name_DESC',
  ContentHubDescriptionAsc = 'content_hub_description_ASC',
  ContentHubDescriptionDesc = 'content_hub_description_DESC'
}

export enum SortContentPosty {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  PublicationDateOverrideAsc = 'publication_date_override_ASC',
  PublicationDateOverrideDesc = 'publication_date_override_DESC',
  ContentTypeAsc = 'content_type_ASC',
  ContentTypeDesc = 'content_type_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  FormTitleAsc = 'form_title_ASC',
  FormTitleDesc = 'form_title_DESC',
  FormSubtitleAsc = 'form_subtitle_ASC',
  FormSubtitleDesc = 'form_subtitle_DESC',
  HubspotFormIdAsc = 'hubspot_form_id_ASC',
  HubspotFormIdDesc = 'hubspot_form_id_DESC'
}

export enum SortCtaContenty {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC'
}

export enum SortDocumentsBy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortFootery {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  ColumnTitle_1Asc = 'column_title_1_ASC',
  ColumnTitle_1Desc = 'column_title_1_DESC',
  ColumnTitle_2Asc = 'column_title_2_ASC',
  ColumnTitle_2Desc = 'column_title_2_DESC',
  ColumnTitle_3Asc = 'column_title_3_ASC',
  ColumnTitle_3Desc = 'column_title_3_DESC'
}

export enum SortGoGreeny {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SubheadlineAsc = 'subheadline_ASC',
  SubheadlineDesc = 'subheadline_DESC',
  TextAboveReporterElementAsc = 'text_above_reporter_element_ASC',
  TextAboveReporterElementDesc = 'text_above_reporter_element_DESC',
  TextBelowReporterElementAsc = 'text_below_reporter_element_ASC',
  TextBelowReporterElementDesc = 'text_below_reporter_element_DESC'
}

export enum SortHeaderCtasy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortHeaderLinksy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortHeadery {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortHomey {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortJobConfirmationBannersy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  ButtonTextAsc = 'button_text_ASC',
  ButtonTextDesc = 'button_text_DESC'
}

export enum SortLandingPageGroupy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortMainMenuy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortMarketplaceRequestStartLeftContenty {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  LargeTextParagraphAsc = 'large_text_paragraph_ASC',
  LargeTextParagraphDesc = 'large_text_paragraph_DESC',
  SmallTextParagraphAsc = 'small_text_paragraph_ASC',
  SmallTextParagraphDesc = 'small_text_paragraph_DESC'
}

export enum SortMaterialTypey {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  MaterialIdAsc = 'material_id_ASC',
  MaterialIdDesc = 'material_id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ApplicationsAsc = 'applications_ASC',
  ApplicationsDesc = 'applications_DESC',
  MaterialCategoryAsc = 'material_category_ASC',
  MaterialCategoryDesc = 'material_category_DESC',
  ProcessAsc = 'process_ASC',
  ProcessDesc = 'process_DESC',
  SubprocessAsc = 'subprocess_ASC',
  SubprocessDesc = 'subprocess_DESC',
  CostAsc = 'cost_ASC',
  CostDesc = 'cost_DESC'
}

export enum SortMaterialsy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC'
}

export enum SortMaterialy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortNavy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  NavTitleAsc = 'nav_title_ASC',
  NavTitleDesc = 'nav_title_DESC'
}

export enum SortOnboardingy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtextAsc = 'subtext_ASC',
  SubtextDesc = 'subtext_DESC',
  BenefitTextOneAsc = 'benefit_text_one_ASC',
  BenefitTextOneDesc = 'benefit_text_one_DESC',
  BenefitTextTwoAsc = 'benefit_text_two_ASC',
  BenefitTextTwoDesc = 'benefit_text_two_DESC',
  BenefitTextThreeAsc = 'benefit_text_three_ASC',
  BenefitTextThreeDesc = 'benefit_text_three_DESC'
}

export enum SortPartnerToolkitBannery {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtextAsc = 'subtext_ASC',
  SubtextDesc = 'subtext_DESC'
}

export enum SortPersonalizedBannery {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SubheadlineAsc = 'subheadline_ASC',
  SubheadlineDesc = 'subheadline_DESC',
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  CtaTextAsc = 'cta_text_ASC',
  CtaTextDesc = 'cta_text_DESC'
}

export enum SortPersonalizedStartPageMarketingCardsy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortRedirectsy {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortSelfServeSubscriptiony {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  BenefitOneAsc = 'benefit_one_ASC',
  BenefitOneDesc = 'benefit_one_DESC',
  BenefitTwoAsc = 'benefit_two_ASC',
  BenefitTwoDesc = 'benefit_two_DESC',
  BenefitThreeAsc = 'benefit_three_ASC',
  BenefitThreeDesc = 'benefit_three_DESC',
  BenefitFourAsc = 'benefit_four_ASC',
  BenefitFourDesc = 'benefit_four_DESC',
  BenefitFiveAsc = 'benefit_five_ASC',
  BenefitFiveDesc = 'benefit_five_DESC',
  BenefitSixAsc = 'benefit_six_ASC',
  BenefitSixDesc = 'benefit_six_DESC',
  BenefitSevenAsc = 'benefit_seven_ASC',
  BenefitSevenDesc = 'benefit_seven_DESC'
}

export enum SortShortLandingPagey {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortStandardLandingPagey {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC'
}

export enum SortUltimateGuidey {
  MetaFirstPublicationDateAsc = 'meta_firstPublicationDate_ASC',
  MetaFirstPublicationDateDesc = 'meta_firstPublicationDate_DESC',
  MetaLastPublicationDateAsc = 'meta_lastPublicationDate_ASC',
  MetaLastPublicationDateDesc = 'meta_lastPublicationDate_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SubheadlineAsc = 'subheadline_ASC',
  SubheadlineDesc = 'subheadline_DESC',
  CtaTextAsc = 'cta_text_ASC',
  CtaTextDesc = 'cta_text_DESC'
}

export type StandardLandingPage = Document & Linkable & {
  readonly parent_id?: Maybe<Linkable>;
  readonly header_links?: Maybe<Linkable>;
  readonly header_ctas?: Maybe<Linkable>;
  readonly body?: Maybe<ReadonlyArray<StandardLandingPageBody>>;
  readonly body1?: Maybe<ReadonlyArray<StandardLandingPageBody1>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBody = StandardLandingPageBodyLandingPageHero | StandardLandingPageBodyTestimonial | StandardLandingPageBodyThreeSimpleCards | StandardLandingPageBodyFullWidthCta | StandardLandingPageBodyTextAndImage | StandardLandingPageBodyThreeSimpleCircles | StandardLandingPageBodyYoutubeVideo | StandardLandingPageBodyNestedContent | StandardLandingPageBodyCapabilityList | StandardLandingPageBodyContentPost | StandardLandingPageBodyCenteredHero | StandardLandingPageBodyResourcesPosts | StandardLandingPageBodyImageGrid | StandardLandingPageBodyRichText | StandardLandingPageBodyBenefitQuadrant | StandardLandingPageBodyMediaMarquee | StandardLandingPageBodyTestimonials | StandardLandingPageBodyImageAndList | StandardLandingPageBodyImageCarousel | StandardLandingPageBodyFeaturedCustomers | StandardLandingPageBodyNewsletterSignup | StandardLandingPageBodyCenteredFullWidthCta | StandardLandingPageBodyTable | StandardLandingPageBodyFormHero | StandardLandingPageBodyLinkGrid | StandardLandingPageBodyCarbonOffset | StandardLandingPageBodyAdvancedTable | StandardLandingPageBodyImageAndCaption | StandardLandingPageBodyNavigationTitle | StandardLandingPageBodyQueriedResourcePosts | StandardLandingPageBodySimpleInlineCta | StandardLandingPageBodyXometryEverywhere | StandardLandingPageBodyPullQuote | StandardLandingPageBodyCountUp | StandardLandingPageBodyInlineCta | StandardLandingPageBodyTextAndModelFile | StandardLandingPageBodyQuoteUploadComponent | StandardLandingPageBodyCustomerRfqCta | StandardLandingPageBodyLatestCapabilities | StandardLandingPageBodyHeroVideo | StandardLandingPageBodyVideoCta | StandardLandingPageBodyTextAndImageRows | StandardLandingPageBodyImageCta | StandardLandingPageBodyReusableCta | StandardLandingPageBodyTextAndImageV2 | StandardLandingPageBodyRepeatableRichText | StandardLandingPageBodySectionDivider | StandardLandingPageBodyAdvancedSlice | StandardLandingPageBodyAuthorPosts | StandardLandingPageBodyHeroAuthor;

export type StandardLandingPageBody1 = StandardLandingPageBody1Seo | StandardLandingPageBody1PageLocales;

export type StandardLandingPageBody1PageLocales = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBody1PageLocalesFields>>;
};

export type StandardLandingPageBody1PageLocalesFields = {
  readonly locale?: Maybe<Scalars['String']>;
  readonly page?: Maybe<Linkable>;
};

export type StandardLandingPageBody1Seo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBody1SeoPrimary>;
};

export type StandardLandingPageBody1SeoPrimary = {
  readonly meta_title?: Maybe<Scalars['String']>;
  readonly meta_description?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyAdvancedSlice = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyAdvancedSlicePrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyAdvancedSliceFields>>;
};

export type StandardLandingPageBodyAdvancedSliceFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
  readonly content_post?: Maybe<Linkable>;
};

export type StandardLandingPageBodyAdvancedSlicePrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly text_and_image_alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly use_image_as_background?: Maybe<Scalars['Boolean']>;
  readonly image_background_alignment?: Maybe<Scalars['String']>;
  readonly repeatable_layout?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyAdvancedTable = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyAdvancedTablePrimary>;
};

export type StandardLandingPageBodyAdvancedTablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption?: Maybe<Scalars['Json']>;
  readonly csv_file?: Maybe<Linkable>;
  readonly csv_test?: Maybe<Linkable>;
  readonly image_test?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyAuthorPosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyAuthorPostsPrimary>;
};

export type StandardLandingPageBodyAuthorPostsPrimary = {
  readonly title?: Maybe<Scalars['Json']>;
  readonly author?: Maybe<Linkable>;
};

export type StandardLandingPageBodyBenefitQuadrant = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyBenefitQuadrantPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyBenefitQuadrantFields>>;
};

export type StandardLandingPageBodyBenefitQuadrantFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyBenefitQuadrantPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['Json']>;
  readonly footer?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyCapabilityList = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyCapabilityListPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyCapabilityListFields>>;
};

export type StandardLandingPageBodyCapabilityListFields = {
  readonly capability?: Maybe<Linkable>;
};

export type StandardLandingPageBodyCapabilityListPrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyCarbonOffset = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyCarbonOffsetPrimary>;
};

export type StandardLandingPageBodyCarbonOffsetPrimary = {
  readonly header?: Maybe<Scalars['Json']>;
  readonly text?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyCenteredFullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyCenteredFullWidthCtaPrimary>;
};

export type StandardLandingPageBodyCenteredFullWidthCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
};

export type StandardLandingPageBodyCenteredHero = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyCenteredHeroPrimary>;
};

export type StandardLandingPageBodyCenteredHeroPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_microcopy?: Maybe<Scalars['Boolean']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyContentPost = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyContentPostPrimary>;
};

export type StandardLandingPageBodyContentPostPrimary = {
  readonly content_post?: Maybe<Linkable>;
  readonly content_icon?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyCountUp = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyCountUpPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyCountUpFields>>;
};

export type StandardLandingPageBodyCountUpFields = {
  readonly category_image?: Maybe<Scalars['Json']>;
  readonly category_title?: Maybe<Scalars['String']>;
  readonly number?: Maybe<Scalars['Float']>;
  readonly suffix?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyCountUpPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly count_up_subtitle?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyCustomerRfqCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyCustomerRfqCtaPrimary>;
};

export type StandardLandingPageBodyCustomerRfqCtaPrimary = {
  readonly customer_rfq_cta_subtitle?: Maybe<Scalars['Json']>;
  readonly customer_rfq_cta_button_text?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyFeaturedCustomers = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyFeaturedCustomersPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyFeaturedCustomersFields>>;
};

export type StandardLandingPageBodyFeaturedCustomersFields = {
  readonly company_logo?: Maybe<Scalars['Json']>;
  readonly company_link?: Maybe<Linkable>;
};

export type StandardLandingPageBodyFeaturedCustomersPrimary = {
  readonly subtitle?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyFormHero = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyFormHeroPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyFormHeroFields>>;
};

export type StandardLandingPageBodyFormHeroFields = {
  readonly quick_fact_text?: Maybe<Scalars['Json']>;
  readonly quick_fact_icon?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyFormHeroPrimary = {
  readonly single_column?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly form_title?: Maybe<Scalars['String']>;
  readonly form_subtitle?: Maybe<Scalars['Json']>;
  readonly hubspot_form_id?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyFullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyFullWidthCtaPrimary>;
};

export type StandardLandingPageBodyFullWidthCtaPrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly fine_print?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyHeroAuthor = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyHeroAuthorPrimary>;
};

export type StandardLandingPageBodyHeroAuthorPrimary = {
  readonly author?: Maybe<Linkable>;
};

export type StandardLandingPageBodyHeroVideo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyHeroVideoPrimary>;
};

export type StandardLandingPageBodyHeroVideoPrimary = {
  readonly hero_type?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly video?: Maybe<Linkable>;
  readonly video_poster?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly use_salesforce_form?: Maybe<Scalars['Boolean']>;
  readonly salesforce_lead_source?: Maybe<Scalars['String']>;
  readonly salesforce_form_disclaimer?: Maybe<Scalars['Json']>;
  readonly vimeo?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyImageAndList = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyImageAndListPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyImageAndListFields>>;
};

export type StandardLandingPageBodyImageAndListFields = {
  readonly image1?: Maybe<Scalars['Json']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyImageAndListPrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyImageAndCaption = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyImageAndCaptionPrimary>;
};

export type StandardLandingPageBodyImageAndCaptionPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly caption?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyImageCarousel = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyImageCarouselPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyImageCarouselFields>>;
};

export type StandardLandingPageBodyImageCarouselFields = {
  readonly image1?: Maybe<Scalars['Json']>;
  readonly image_caption?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyImageCarouselPrimary = {
  readonly primary_caption?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyImageCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyImageCtaPrimary>;
};

export type StandardLandingPageBodyImageCtaPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyImageGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyImageGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyImageGridFields>>;
};

export type StandardLandingPageBodyImageGridFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyImageGridPrimary = {
  readonly columns?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyInlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyInlineCtaPrimary>;
};

export type StandardLandingPageBodyInlineCtaPrimary = {
  readonly copy?: Maybe<Scalars['String']>;
  readonly cta_text1?: Maybe<Scalars['String']>;
  readonly cta_link1?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyLandingPageHero = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyLandingPageHeroPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyLandingPageHeroFields>>;
};

export type StandardLandingPageBodyLandingPageHeroFields = {
  readonly quick_fact_text?: Maybe<Scalars['Json']>;
  readonly quick_fact_icon?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyLandingPageHeroPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['Json']>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_microcopy?: Maybe<Scalars['Boolean']>;
  readonly rfq_cta_type?: Maybe<Scalars['String']>;
  readonly rfq_cta_section_title?: Maybe<Scalars['String']>;
  readonly customer_rfq_prompt_text?: Maybe<Scalars['String']>;
  readonly customer_rfq_prompt_button_text?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyLatestCapabilities = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyLatestCapabilitiesPrimary>;
};

export type StandardLandingPageBodyLatestCapabilitiesPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly publication_date_sort_order?: Maybe<Scalars['String']>;
  readonly parent?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly number_of_post?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyLinkGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyLinkGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyLinkGridFields>>;
};

export type StandardLandingPageBodyLinkGridFields = {
  readonly link_text?: Maybe<Scalars['String']>;
  readonly link?: Maybe<Linkable>;
};

export type StandardLandingPageBodyLinkGridPrimary = {
  readonly link_grid_title?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyMediaMarquee = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyMediaMarqueePrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyMediaMarqueeFields>>;
};

export type StandardLandingPageBodyMediaMarqueeFields = {
  readonly feature_image?: Maybe<Scalars['Json']>;
  readonly feature_title?: Maybe<Scalars['String']>;
  readonly feature_description?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyMediaMarqueePrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly media_marquee_description?: Maybe<Scalars['Json']>;
  readonly video_url?: Maybe<Scalars['Json']>;
  readonly footer?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyNavigationTitle = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyNavigationTitlePrimary>;
};

export type StandardLandingPageBodyNavigationTitlePrimary = {
  readonly title1?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyNestedContent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyNestedContentPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyNestedContentFields>>;
};

export type StandardLandingPageBodyNestedContentFields = {
  readonly header?: Maybe<Scalars['String']>;
  readonly content?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyNestedContentPrimary = {
  readonly width?: Maybe<Scalars['String']>;
  readonly is_faq?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly title_alignment?: Maybe<Scalars['String']>;
  readonly expand_first_item?: Maybe<Scalars['Boolean']>;
  readonly content_layout?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyNewsletterSignup = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyNewsletterSignupPrimary>;
};

export type StandardLandingPageBodyNewsletterSignupPrimary = {
  readonly description?: Maybe<Scalars['String']>;
  readonly cta_button_text?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyPullQuote = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyPullQuotePrimary>;
};

export type StandardLandingPageBodyPullQuotePrimary = {
  readonly quote?: Maybe<Scalars['String']>;
  readonly attribution_name?: Maybe<Scalars['String']>;
  readonly attribution_title?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyQueriedResourcePosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyQueriedResourcePostsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyQueriedResourcePostsFields>>;
};

export type StandardLandingPageBodyQueriedResourcePostsFields = {
  readonly content_category_filter?: Maybe<Linkable>;
  readonly author_filter?: Maybe<Linkable>;
  readonly hide_author_name?: Maybe<Scalars['Boolean']>;
};

export type StandardLandingPageBodyQueriedResourcePostsPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly publication_date_sort_order?: Maybe<Scalars['String']>;
  readonly number_of_posts?: Maybe<Scalars['String']>;
  readonly content_type_filter?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
};

export type StandardLandingPageBodyQuoteUploadComponent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyQuoteUploadComponentPrimary>;
};

export type StandardLandingPageBodyQuoteUploadComponentPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['String']>;
  readonly upload_component_heading?: Maybe<Scalars['String']>;
  readonly upload_component_cta?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyRepeatableRichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyRepeatableRichTextPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyRepeatableRichTextFields>>;
};

export type StandardLandingPageBodyRepeatableRichTextFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyRepeatableRichTextPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyResourcesPosts = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyResourcesPostsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyResourcesPostsFields>>;
};

export type StandardLandingPageBodyResourcesPostsFields = {
  readonly content_post?: Maybe<Linkable>;
};

export type StandardLandingPageBodyResourcesPostsPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly button_link?: Maybe<Linkable>;
  readonly button_text?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyReusableCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyReusableCtaPrimary>;
};

export type StandardLandingPageBodyReusableCtaPrimary = {
  readonly link_to_document?: Maybe<Linkable>;
};

export type StandardLandingPageBodyRichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyRichTextPrimary>;
};

export type StandardLandingPageBodyRichTextPrimary = {
  readonly highlight_section?: Maybe<Scalars['Boolean']>;
  readonly title1?: Maybe<Scalars['String']>;
  readonly rich_text?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodySectionDivider = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodySectionDividerPrimary>;
};

export type StandardLandingPageBodySectionDividerPrimary = {
  readonly transition?: Maybe<Scalars['String']>;
  readonly illustration?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodySimpleInlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodySimpleInlineCtaPrimary>;
};

export type StandardLandingPageBodySimpleInlineCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
};

export type StandardLandingPageBodyTable = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyTablePrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyTableFields>>;
};

export type StandardLandingPageBodyTableFields = {
  readonly column_1_entry?: Maybe<Scalars['Json']>;
  readonly column_2_entry?: Maybe<Scalars['Json']>;
  readonly column_3_entry?: Maybe<Scalars['Json']>;
  readonly column_4_entry?: Maybe<Scalars['Json']>;
  readonly column_5_entry?: Maybe<Scalars['Json']>;
  readonly column_6_entry?: Maybe<Scalars['Json']>;
  readonly column_7_entry?: Maybe<Scalars['Json']>;
  readonly column_8_entry?: Maybe<Scalars['Json']>;
  readonly column_9_entry?: Maybe<Scalars['Json']>;
  readonly column_10_entry?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyTablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption_rich_text?: Maybe<Scalars['Json']>;
  readonly column_1_header?: Maybe<Scalars['String']>;
  readonly column_2_header?: Maybe<Scalars['String']>;
  readonly column_3_header?: Maybe<Scalars['String']>;
  readonly column_4_header?: Maybe<Scalars['String']>;
  readonly column_5_header?: Maybe<Scalars['String']>;
  readonly column_6_header?: Maybe<Scalars['String']>;
  readonly column_7_header?: Maybe<Scalars['String']>;
  readonly column_8_header?: Maybe<Scalars['String']>;
  readonly column_9_header?: Maybe<Scalars['String']>;
  readonly column_10_header?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyTestimonial = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyTestimonialPrimary>;
};

export type StandardLandingPageBodyTestimonialPrimary = {
  readonly quote?: Maybe<Scalars['String']>;
  readonly person_name?: Maybe<Scalars['String']>;
  readonly person_title?: Maybe<Scalars['String']>;
  readonly person_image?: Maybe<Scalars['Json']>;
  readonly company_image?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyTestimonials = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyTestimonialsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyTestimonialsFields>>;
};

export type StandardLandingPageBodyTestimonialsFields = {
  readonly person_image?: Maybe<Scalars['Json']>;
  readonly company_image?: Maybe<Scalars['Json']>;
  readonly quote?: Maybe<Scalars['String']>;
  readonly person_name?: Maybe<Scalars['String']>;
  readonly person_title?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyTestimonialsPrimary = {
  readonly title?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyTextAndImage = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyTextAndImagePrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyTextAndImageFields>>;
};

export type StandardLandingPageBodyTextAndImageFields = {
  readonly bullet_point?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyTextAndImagePrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly display_new_badge?: Maybe<Scalars['Boolean']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyTextAndImageRows = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyTextAndImageRowsPrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyTextAndImageRowsFields>>;
};

export type StandardLandingPageBodyTextAndImageRowsFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly heading?: Maybe<Scalars['Json']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyTextAndImageRowsPrimary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyTextAndImageV2 = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyTextAndImageV2Primary>;
};

export type StandardLandingPageBodyTextAndImageV2Primary = {
  readonly theme?: Maybe<Scalars['String']>;
  readonly padding?: Maybe<Scalars['String']>;
  readonly text_and_image_alignment?: Maybe<Scalars['String']>;
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly image?: Maybe<Scalars['Json']>;
  readonly use_image_as_background?: Maybe<Scalars['Boolean']>;
  readonly image_background_alignment?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyTextAndModelFile = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyTextAndModelFilePrimary>;
  readonly fields?: Maybe<ReadonlyArray<StandardLandingPageBodyTextAndModelFileFields>>;
};

export type StandardLandingPageBodyTextAndModelFileFields = {
  readonly bullet_point?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyTextAndModelFilePrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly display_new_badge?: Maybe<Scalars['Boolean']>;
  readonly text?: Maybe<Scalars['Json']>;
  readonly model_file?: Maybe<Linkable>;
  readonly cta_text?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyThreeSimpleCards = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyThreeSimpleCardsPrimary>;
};

export type StandardLandingPageBodyThreeSimpleCardsPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly card_1_image?: Maybe<Scalars['Json']>;
  readonly card_1_title?: Maybe<Scalars['String']>;
  readonly card_1_description?: Maybe<Scalars['Json']>;
  readonly card_2_image?: Maybe<Scalars['Json']>;
  readonly card_2_title?: Maybe<Scalars['String']>;
  readonly card_2_description?: Maybe<Scalars['Json']>;
  readonly card_3_image?: Maybe<Scalars['Json']>;
  readonly card_3_title?: Maybe<Scalars['String']>;
  readonly card_3_description?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyThreeSimpleCircles = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyThreeSimpleCirclesPrimary>;
};

export type StandardLandingPageBodyThreeSimpleCirclesPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly circle_1_image?: Maybe<Scalars['Json']>;
  readonly circle_1_title?: Maybe<Scalars['String']>;
  readonly circle_1_description?: Maybe<Scalars['Json']>;
  readonly circle_2_image?: Maybe<Scalars['Json']>;
  readonly circle_2_title?: Maybe<Scalars['String']>;
  readonly circle_2_description?: Maybe<Scalars['Json']>;
  readonly circle_3_image?: Maybe<Scalars['Json']>;
  readonly circle_3_title?: Maybe<Scalars['String']>;
  readonly circle_3_description?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyVideoCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyVideoCtaPrimary>;
};

export type StandardLandingPageBodyVideoCtaPrimary = {
  readonly primary_heading?: Maybe<Scalars['Json']>;
  readonly primary_description?: Maybe<Scalars['Json']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly video_url?: Maybe<Scalars['Json']>;
};

export type StandardLandingPageBodyXometryEverywhere = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
};

export type StandardLandingPageBodyYoutubeVideo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<StandardLandingPageBodyYoutubeVideoPrimary>;
};

export type StandardLandingPageBodyYoutubeVideoPrimary = {
  readonly title?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['Json']>;
};

/** A connection to a list of items. */
export type StandardLandingPageConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<StandardLandingPageConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type StandardLandingPageConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: StandardLandingPage;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type UltimateGuide = Document & Linkable & {
  readonly headline?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['String']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_link?: Maybe<Linkable>;
  readonly hero_image?: Maybe<Scalars['Json']>;
  readonly body1?: Maybe<ReadonlyArray<UltimateGuideBody1>>;
  readonly body?: Maybe<ReadonlyArray<UltimateGuideBody>>;
  readonly _meta: Meta;
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type UltimateGuideBody = UltimateGuideBodySeo | UltimateGuideBodyPageLocales;

export type UltimateGuideBody1 = UltimateGuideBody1Chapter | UltimateGuideBody1ChapterSection | UltimateGuideBody1NestedContent | UltimateGuideBody1RichText | UltimateGuideBody1ImageAndCaption | UltimateGuideBody1InlineCta | UltimateGuideBody1OffsetImages | UltimateGuideBody1BulletedFullWidthCta | UltimateGuideBody1RichtextAndLinkCard | UltimateGuideBody1RichTextNewsletterCard | UltimateGuideBody1RichTextAndDownloadCard | UltimateGuideBody1CenteredFullWidthCta | UltimateGuideBody1Table | UltimateGuideBody1ImageGrid;

export type UltimateGuideBody1BulletedFullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1BulletedFullWidthCtaPrimary>;
};

export type UltimateGuideBody1BulletedFullWidthCtaPrimary = {
  readonly headline1?: Maybe<Scalars['String']>;
  readonly value_point_1?: Maybe<Scalars['Json']>;
  readonly value_point_2?: Maybe<Scalars['Json']>;
  readonly value_point_3?: Maybe<Scalars['Json']>;
  readonly cta_text1?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_link1?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type UltimateGuideBody1CenteredFullWidthCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1CenteredFullWidthCtaPrimary>;
};

export type UltimateGuideBody1CenteredFullWidthCtaPrimary = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_link?: Maybe<Linkable>;
};

export type UltimateGuideBody1Chapter = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1ChapterPrimary>;
};

export type UltimateGuideBody1ChapterPrimary = {
  readonly chapter_title?: Maybe<Scalars['String']>;
  readonly chapter_summary?: Maybe<Scalars['String']>;
  readonly chapter_image?: Maybe<Scalars['Json']>;
};

export type UltimateGuideBody1ChapterSection = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1ChapterSectionPrimary>;
};

export type UltimateGuideBody1ChapterSectionPrimary = {
  readonly section_title?: Maybe<Scalars['String']>;
};

export type UltimateGuideBody1ImageAndCaption = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1ImageAndCaptionPrimary>;
};

export type UltimateGuideBody1ImageAndCaptionPrimary = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly caption?: Maybe<Scalars['String']>;
};

export type UltimateGuideBody1ImageGrid = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1ImageGridPrimary>;
  readonly fields?: Maybe<ReadonlyArray<UltimateGuideBody1ImageGridFields>>;
};

export type UltimateGuideBody1ImageGridFields = {
  readonly image?: Maybe<Scalars['Json']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['Json']>;
};

export type UltimateGuideBody1ImageGridPrimary = {
  readonly title?: Maybe<Scalars['String']>;
};

export type UltimateGuideBody1InlineCta = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1InlineCtaPrimary>;
};

export type UltimateGuideBody1InlineCtaPrimary = {
  readonly copy?: Maybe<Scalars['String']>;
  readonly cta_text1?: Maybe<Scalars['String']>;
  readonly cta_link1?: Maybe<Linkable>;
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly image?: Maybe<Scalars['Json']>;
};

export type UltimateGuideBody1NestedContent = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1NestedContentPrimary>;
  readonly fields?: Maybe<ReadonlyArray<UltimateGuideBody1NestedContentFields>>;
};

export type UltimateGuideBody1NestedContentFields = {
  readonly header?: Maybe<Scalars['String']>;
  readonly content?: Maybe<Scalars['Json']>;
};

export type UltimateGuideBody1NestedContentPrimary = {
  readonly is_faq?: Maybe<Scalars['Boolean']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type UltimateGuideBody1OffsetImages = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1OffsetImagesPrimary>;
};

export type UltimateGuideBody1OffsetImagesPrimary = {
  readonly left_image?: Maybe<Scalars['Json']>;
  readonly right_image?: Maybe<Scalars['Json']>;
  readonly caption?: Maybe<Scalars['String']>;
};

export type UltimateGuideBody1RichText = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1RichTextPrimary>;
};

export type UltimateGuideBody1RichTextPrimary = {
  readonly rich_text?: Maybe<Scalars['Json']>;
};

export type UltimateGuideBody1RichTextNewsletterCard = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1RichTextNewsletterCardPrimary>;
};

export type UltimateGuideBody1RichTextNewsletterCardPrimary = {
  readonly rich_text_content?: Maybe<Scalars['Json']>;
  readonly card_subheadline?: Maybe<Scalars['String']>;
  readonly card_headline?: Maybe<Scalars['String']>;
  readonly card_image?: Maybe<Scalars['Json']>;
  readonly card_description?: Maybe<Scalars['String']>;
};

export type UltimateGuideBody1RichTextAndDownloadCard = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1RichTextAndDownloadCardPrimary>;
};

export type UltimateGuideBody1RichTextAndDownloadCardPrimary = {
  readonly rich_text_content?: Maybe<Scalars['Json']>;
  readonly card_subheadline?: Maybe<Scalars['String']>;
  readonly card_headline?: Maybe<Scalars['String']>;
  readonly card_image?: Maybe<Scalars['Json']>;
  readonly card_cta_text?: Maybe<Scalars['String']>;
  readonly file_link?: Maybe<Linkable>;
  readonly card_description?: Maybe<Scalars['String']>;
};

export type UltimateGuideBody1RichtextAndLinkCard = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1RichtextAndLinkCardPrimary>;
};

export type UltimateGuideBody1RichtextAndLinkCardPrimary = {
  readonly rich_text_content?: Maybe<Scalars['Json']>;
  readonly card_image?: Maybe<Scalars['Json']>;
  readonly card_subheadline?: Maybe<Scalars['String']>;
  readonly card_headline?: Maybe<Scalars['String']>;
  readonly card_content?: Maybe<Scalars['String']>;
  readonly card_link_text?: Maybe<Scalars['String']>;
  readonly card_link?: Maybe<Linkable>;
};

export type UltimateGuideBody1Table = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBody1TablePrimary>;
  readonly fields?: Maybe<ReadonlyArray<UltimateGuideBody1TableFields>>;
};

export type UltimateGuideBody1TableFields = {
  readonly column_1_entry?: Maybe<Scalars['Json']>;
  readonly column_2_entry?: Maybe<Scalars['Json']>;
  readonly column_3_entry?: Maybe<Scalars['Json']>;
  readonly column_4_entry?: Maybe<Scalars['Json']>;
  readonly column_5_entry?: Maybe<Scalars['Json']>;
  readonly column_6_entry?: Maybe<Scalars['Json']>;
  readonly column_7_entry?: Maybe<Scalars['Json']>;
  readonly column_8_entry?: Maybe<Scalars['Json']>;
  readonly column_9_entry?: Maybe<Scalars['Json']>;
  readonly column_10_entry?: Maybe<Scalars['Json']>;
};

export type UltimateGuideBody1TablePrimary = {
  readonly heading?: Maybe<Scalars['String']>;
  readonly table_title?: Maybe<Scalars['String']>;
  readonly table_caption_rich_text?: Maybe<Scalars['Json']>;
  readonly column_1_header?: Maybe<Scalars['String']>;
  readonly column_2_header?: Maybe<Scalars['String']>;
  readonly column_3_header?: Maybe<Scalars['String']>;
  readonly column_4_header?: Maybe<Scalars['String']>;
  readonly column_5_header?: Maybe<Scalars['String']>;
  readonly column_6_header?: Maybe<Scalars['String']>;
  readonly column_7_header?: Maybe<Scalars['String']>;
  readonly column_8_header?: Maybe<Scalars['String']>;
  readonly column_9_header?: Maybe<Scalars['String']>;
  readonly column_10_header?: Maybe<Scalars['String']>;
};

export type UltimateGuideBodyPageLocales = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<UltimateGuideBodyPageLocalesFields>>;
};

export type UltimateGuideBodyPageLocalesFields = {
  readonly locale?: Maybe<Scalars['String']>;
  readonly page?: Maybe<Linkable>;
};

export type UltimateGuideBodySeo = {
  readonly type?: Maybe<Scalars['String']>;
  readonly label?: Maybe<Scalars['String']>;
  readonly primary?: Maybe<UltimateGuideBodySeoPrimary>;
};

export type UltimateGuideBodySeoPrimary = {
  readonly meta_title?: Maybe<Scalars['String']>;
  readonly meta_description?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type UltimateGuideConnectionConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<UltimateGuideConnectionEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type UltimateGuideConnectionEdge = {
  /** The item at the end of the edge. */
  readonly node: UltimateGuide;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

export type WhereAnnouncementBanner = {
  readonly title?: Maybe<Scalars['String']>;
  readonly title_fulltext?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly subtitle_fulltext?: Maybe<Scalars['String']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_text_fulltext?: Maybe<Scalars['String']>;
  /** cta_url */
  readonly cta_url?: Maybe<Scalars['String']>;
};

export type WhereAuthor = {
  readonly honorific_prefix?: Maybe<Scalars['String']>;
  readonly honorific_prefix_fulltext?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly name_fulltext?: Maybe<Scalars['String']>;
  readonly honorific_suffix?: Maybe<Scalars['String']>;
  readonly honorific_suffix_fulltext?: Maybe<Scalars['String']>;
  readonly bio?: Maybe<Scalars['String']>;
  readonly bio_fulltext?: Maybe<Scalars['String']>;
  /** author_page */
  readonly author_page?: Maybe<Scalars['String']>;
  readonly same_as_links?: Maybe<WhereAuthorSameAsLinks>;
};

export type WhereAuthorSameAsLinks = {
  /** same_as_link */
  readonly same_as_link?: Maybe<Scalars['String']>;
};

export type WhereCapability = {
  /** parent_id */
  readonly parent_id?: Maybe<Scalars['String']>;
};

export type WhereContentCategory = {
  readonly category_title?: Maybe<Scalars['String']>;
  readonly category_title_fulltext?: Maybe<Scalars['String']>;
  /** description */
  readonly description_fulltext?: Maybe<Scalars['String']>;
};

export type WhereContentHub = {
  readonly content_hub_name?: Maybe<Scalars['String']>;
  readonly content_hub_name_fulltext?: Maybe<Scalars['String']>;
  /** content_hub_description */
  readonly content_hub_description_fulltext?: Maybe<Scalars['String']>;
  /** large_featured_post */
  readonly large_featured_post?: Maybe<Scalars['String']>;
  readonly small_featured_posts?: Maybe<WhereContentHubSmallFeaturedPosts>;
};

export type WhereContentHubSmallFeaturedPosts = {
  /** post */
  readonly post?: Maybe<Scalars['String']>;
};

export type WhereContentPost = {
  /** publication_date_override */
  readonly publication_date_override?: Maybe<Scalars['Date']>;
  /** publication_date_override */
  readonly publication_date_override_before?: Maybe<Scalars['Date']>;
  /** publication_date_override */
  readonly publication_date_override_after?: Maybe<Scalars['Date']>;
  readonly content_type?: Maybe<Scalars['String']>;
  readonly content_type_fulltext?: Maybe<Scalars['String']>;
  /** category */
  readonly category?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
  readonly title_fulltext?: Maybe<Scalars['String']>;
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly subtitle_fulltext?: Maybe<Scalars['String']>;
  /** author */
  readonly author?: Maybe<Scalars['String']>;
  /** editor */
  readonly editor?: Maybe<Scalars['String']>;
  readonly form_title?: Maybe<Scalars['String']>;
  readonly form_title_fulltext?: Maybe<Scalars['String']>;
  /** form_subtitle */
  readonly form_subtitle_fulltext?: Maybe<Scalars['String']>;
  readonly hubspot_form_id?: Maybe<Scalars['String']>;
  readonly hubspot_form_id_fulltext?: Maybe<Scalars['String']>;
};

export type WhereCtaContent = {
  /** category */
  readonly category?: Maybe<Scalars['String']>;
  /** title */
  readonly title_fulltext?: Maybe<Scalars['String']>;
  /** description */
  readonly description_fulltext?: Maybe<Scalars['String']>;
};

export type WhereFooter = {
  readonly column_title_1?: Maybe<Scalars['String']>;
  readonly column_title_1_fulltext?: Maybe<Scalars['String']>;
  readonly column_text_1?: Maybe<WhereFooterColumnText_1>;
  readonly column_title_2?: Maybe<Scalars['String']>;
  readonly column_title_2_fulltext?: Maybe<Scalars['String']>;
  readonly column_text_2?: Maybe<WhereFooterColumnText_2>;
  readonly column_title_3?: Maybe<Scalars['String']>;
  readonly column_title_3_fulltext?: Maybe<Scalars['String']>;
  readonly column_text_3?: Maybe<WhereFooterColumnText_3>;
  readonly legal_links?: Maybe<WhereFooterLegalLinks>;
};

export type WhereFooterColumnText_1 = {
  /** column_item */
  readonly column_item_fulltext?: Maybe<Scalars['String']>;
};

export type WhereFooterColumnText_2 = {
  /** column_item */
  readonly column_item_fulltext?: Maybe<Scalars['String']>;
};

export type WhereFooterColumnText_3 = {
  /** column_item */
  readonly column_item_fulltext?: Maybe<Scalars['String']>;
};

export type WhereFooterLegalLinks = {
  /** legal_link */
  readonly legal_link_fulltext?: Maybe<Scalars['String']>;
};

export type WhereGoGreen = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly headline_fulltext?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['String']>;
  readonly subheadline_fulltext?: Maybe<Scalars['String']>;
  /** text_above_reporter_element */
  readonly text_above_reporter_element_fulltext?: Maybe<Scalars['String']>;
  /** text_below_reporter_element */
  readonly text_below_reporter_element_fulltext?: Maybe<Scalars['String']>;
};

export type WhereHeader = {
  readonly menu_links?: Maybe<WhereHeaderMenuLinks>;
  readonly menu_ctas?: Maybe<WhereHeaderMenuCtas>;
};

export type WhereHeaderMenuCtas = {
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_text_fulltext?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_action_fulltext?: Maybe<Scalars['String']>;
  /** cta_link */
  readonly cta_link?: Maybe<Scalars['String']>;
  /** drift_bot_interaction_id */
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  /** drift_bot_interaction_id */
  readonly drift_bot_interaction_id_lt?: Maybe<Scalars['Float']>;
  /** drift_bot_interaction_id */
  readonly drift_bot_interaction_id_gt?: Maybe<Scalars['Float']>;
  /** drift_bot_interaction_id */
  readonly drift_bot_interaction_id_range?: Maybe<ReadonlyArray<Scalars['Float']>>;
};

export type WhereHeaderMenuLinks = {
  readonly link_text?: Maybe<Scalars['String']>;
  readonly link_text_fulltext?: Maybe<Scalars['String']>;
  /** link */
  readonly link?: Maybe<Scalars['String']>;
};

export type WhereHeaderCtas = {
  readonly ctas?: Maybe<WhereHeaderCtasCtas>;
};

export type WhereHeaderCtasCtas = {
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_text_fulltext?: Maybe<Scalars['String']>;
  readonly cta_action?: Maybe<Scalars['String']>;
  readonly cta_action_fulltext?: Maybe<Scalars['String']>;
  /** cta_link */
  readonly cta_link?: Maybe<Scalars['String']>;
  /** drift_bot_interaction_id */
  readonly drift_bot_interaction_id?: Maybe<Scalars['Float']>;
  /** drift_bot_interaction_id */
  readonly drift_bot_interaction_id_lt?: Maybe<Scalars['Float']>;
  /** drift_bot_interaction_id */
  readonly drift_bot_interaction_id_gt?: Maybe<Scalars['Float']>;
  /** drift_bot_interaction_id */
  readonly drift_bot_interaction_id_range?: Maybe<ReadonlyArray<Scalars['Float']>>;
};

export type WhereHeaderLinks = {
  readonly page_links?: Maybe<WhereHeaderLinksPageLinks>;
};

export type WhereHeaderLinksPageLinks = {
  readonly link_text?: Maybe<Scalars['String']>;
  readonly link_text_fulltext?: Maybe<Scalars['String']>;
  /** link */
  readonly link?: Maybe<Scalars['String']>;
};

export type WhereJobConfirmationBanners = {
  readonly title?: Maybe<Scalars['String']>;
  readonly title_fulltext?: Maybe<Scalars['String']>;
  /** text */
  readonly text_fulltext?: Maybe<Scalars['String']>;
  readonly button_text?: Maybe<Scalars['String']>;
  readonly button_text_fulltext?: Maybe<Scalars['String']>;
  /** button_link */
  readonly button_link?: Maybe<Scalars['String']>;
};

export type WhereLandingPageGroup = {
  readonly processes?: Maybe<WhereLandingPageGroupProcesses>;
};

export type WhereLandingPageGroupProcesses = {
  readonly process_name?: Maybe<Scalars['String']>;
  readonly process_name_fulltext?: Maybe<Scalars['String']>;
  /** capability */
  readonly capability?: Maybe<Scalars['String']>;
};

export type WhereMarketplaceRequestStartLeftContent = {
  readonly title?: Maybe<Scalars['String']>;
  readonly title_fulltext?: Maybe<Scalars['String']>;
  /** large_text_paragraph */
  readonly large_text_paragraph_fulltext?: Maybe<Scalars['String']>;
  readonly call_outs?: Maybe<WhereMarketplaceRequestStartLeftContentCallOuts>;
  /** small_text_paragraph */
  readonly small_text_paragraph_fulltext?: Maybe<Scalars['String']>;
  readonly video_links?: Maybe<WhereMarketplaceRequestStartLeftContentVideoLinks>;
};

export type WhereMarketplaceRequestStartLeftContentCallOuts = {
  readonly icon?: Maybe<Scalars['String']>;
  readonly icon_fulltext?: Maybe<Scalars['String']>;
  /** call_out_text */
  readonly call_out_text_fulltext?: Maybe<Scalars['String']>;
};

export type WhereMarketplaceRequestStartLeftContentVideoLinks = {
  readonly link_text?: Maybe<Scalars['String']>;
  readonly link_text_fulltext?: Maybe<Scalars['String']>;
  /** link_destination */
  readonly link_destination?: Maybe<Scalars['String']>;
};

export type WhereMaterialType = {
  readonly material_id?: Maybe<Scalars['String']>;
  readonly material_id_fulltext?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly name_fulltext?: Maybe<Scalars['String']>;
  /** description */
  readonly description_fulltext?: Maybe<Scalars['String']>;
  /** applications */
  readonly applications_fulltext?: Maybe<Scalars['String']>;
  readonly material_category?: Maybe<Scalars['String']>;
  readonly material_category_fulltext?: Maybe<Scalars['String']>;
  /** spec_sheet */
  readonly spec_sheet?: Maybe<Scalars['String']>;
  readonly process?: Maybe<Scalars['String']>;
  readonly process_fulltext?: Maybe<Scalars['String']>;
  readonly subprocess?: Maybe<Scalars['String']>;
  readonly subprocess_fulltext?: Maybe<Scalars['String']>;
  readonly cost?: Maybe<Scalars['String']>;
  readonly cost_fulltext?: Maybe<Scalars['String']>;
  readonly attributes?: Maybe<WhereMaterialTypeAttributes>;
};

export type WhereMaterialTypeAttributes = {
  readonly attribute?: Maybe<Scalars['String']>;
  readonly attribute_fulltext?: Maybe<Scalars['String']>;
};

export type WhereMaterials = {
  /** title */
  readonly title_fulltext?: Maybe<Scalars['String']>;
  /** description */
  readonly description_fulltext?: Maybe<Scalars['String']>;
};

export type WhereNav = {
  readonly nav_title?: Maybe<Scalars['String']>;
  readonly nav_title_fulltext?: Maybe<Scalars['String']>;
};

export type WhereOnboarding = {
  /** title */
  readonly title_fulltext?: Maybe<Scalars['String']>;
  /** subtext */
  readonly subtext_fulltext?: Maybe<Scalars['String']>;
  /** benefit_text_one */
  readonly benefit_text_one_fulltext?: Maybe<Scalars['String']>;
  /** benefit_text_two */
  readonly benefit_text_two_fulltext?: Maybe<Scalars['String']>;
  /** benefit_text_three */
  readonly benefit_text_three_fulltext?: Maybe<Scalars['String']>;
};

export type WherePartnerToolkitBanner = {
  readonly title?: Maybe<Scalars['String']>;
  readonly title_fulltext?: Maybe<Scalars['String']>;
  /** subtext */
  readonly subtext_fulltext?: Maybe<Scalars['String']>;
  /** link */
  readonly link?: Maybe<Scalars['String']>;
};

export type WherePersonalizedBanner = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly headline_fulltext?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['String']>;
  readonly subheadline_fulltext?: Maybe<Scalars['String']>;
  /** body */
  readonly body_fulltext?: Maybe<Scalars['String']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_text_fulltext?: Maybe<Scalars['String']>;
  /** cta_url */
  readonly cta_url?: Maybe<Scalars['String']>;
};

export type WhereRedirects = {
  readonly redirect?: Maybe<WhereRedirectsRedirect>;
};

export type WhereRedirectsRedirect = {
  readonly old_url?: Maybe<Scalars['String']>;
  readonly old_url_fulltext?: Maybe<Scalars['String']>;
  readonly new_url?: Maybe<Scalars['String']>;
  readonly new_url_fulltext?: Maybe<Scalars['String']>;
};

export type WhereSelfServeSubscription = {
  /** benefit_one */
  readonly benefit_one_fulltext?: Maybe<Scalars['String']>;
  /** benefit_two */
  readonly benefit_two_fulltext?: Maybe<Scalars['String']>;
  /** benefit_three */
  readonly benefit_three_fulltext?: Maybe<Scalars['String']>;
  /** benefit_four */
  readonly benefit_four_fulltext?: Maybe<Scalars['String']>;
  /** benefit_five */
  readonly benefit_five_fulltext?: Maybe<Scalars['String']>;
  /** benefit_six */
  readonly benefit_six_fulltext?: Maybe<Scalars['String']>;
  /** benefit_seven */
  readonly benefit_seven_fulltext?: Maybe<Scalars['String']>;
};

export type WhereStandardLandingPage = {
  /** parent_id */
  readonly parent_id?: Maybe<Scalars['String']>;
  /** header_links */
  readonly header_links?: Maybe<Scalars['String']>;
  /** header_ctas */
  readonly header_ctas?: Maybe<Scalars['String']>;
};

export type WhereUltimateGuide = {
  readonly headline?: Maybe<Scalars['String']>;
  readonly headline_fulltext?: Maybe<Scalars['String']>;
  readonly subheadline?: Maybe<Scalars['String']>;
  readonly subheadline_fulltext?: Maybe<Scalars['String']>;
  readonly cta_text?: Maybe<Scalars['String']>;
  readonly cta_text_fulltext?: Maybe<Scalars['String']>;
  /** cta_link */
  readonly cta_link?: Maybe<Scalars['String']>;
};

/** A prismic document */
export type Document = {
  readonly _meta: Meta;
};

/** A connection to a list of items. */
export type DocumentConnection = {
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  /** A list of edges. */
  readonly edges?: Maybe<ReadonlyArray<Maybe<DocumentEdge>>>;
  readonly totalCount: Scalars['Long'];
};

/** An edge in a connection. */
export type DocumentEdge = {
  /** The item at the end of the edge. */
  readonly node: Document;
  /** A cursor for use in pagination. */
  readonly cursor: Scalars['String'];
};

/** An external link */
export type ExternalLink = Linkable & {
  readonly url: Scalars['String'];
  readonly target?: Maybe<Scalars['String']>;
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A linked file */
export type FileLink = Linkable & {
  readonly name: Scalars['String'];
  readonly url: Scalars['String'];
  readonly size: Scalars['Long'];
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A linked image */
export type ImageLink = Linkable & {
  readonly name: Scalars['String'];
  readonly url: Scalars['String'];
  readonly size: Scalars['Long'];
  readonly height: Scalars['Int'];
  readonly width: Scalars['Int'];
  readonly _linkType?: Maybe<Scalars['String']>;
};

/** A prismic link */
export type Linkable = {
  readonly _linkType?: Maybe<Scalars['String']>;
};

export type Similar = {
  readonly documentId: Scalars['String'];
  readonly max: Scalars['Int'];
};


export const GetCardsQuery = gql`
    query getCards {
  allPersonalized_start_page_marketing_cardss {
    edges {
      node {
        body {
          ... on Personalized_start_page_marketing_cardsBodyPersonalized_start_page_marketing_card {
            primary {
              card_id
              default_content
              header_title
              header_subtitle
              header_link_text
              header_link_url {
                ... on _ExternalLink {
                  url
                }
              }
              header_image
              card_body
              body_image
              cta_text
              cta_url {
                ... on _ExternalLink {
                  url
                }
              }
              new_label
              split_name
            }
          }
        }
      }
    }
  }
}
    `;
export const GetAnnouncementBannerQuery = gql`
    query getAnnouncementBanner {
  allAnnouncement_banners {
    edges {
      node {
        title
        subtitle
        cta_text
        cta_url {
          ... on _ExternalLink {
            url
          }
        }
      }
    }
  }
}
    `;
export type GetCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCardsQueryResult = { readonly allPersonalized_start_page_marketing_cardss: { readonly edges?: Maybe<ReadonlyArray<Maybe<{ readonly node: { readonly body?: Maybe<ReadonlyArray<{ readonly primary?: Maybe<(
            Pick<PersonalizedStartPageMarketingCardsBodyPersonalizedStartPageMarketingCardPrimary, 'card_id' | 'default_content' | 'header_title' | 'header_subtitle' | 'header_link_text' | 'header_image' | 'card_body' | 'body_image' | 'cta_text' | 'new_label' | 'split_name'>
            & { readonly header_link_url?: Maybe<Pick<ExternalLink, 'url'>>, readonly cta_url?: Maybe<Pick<ExternalLink, 'url'>> }
          )> }>> } }>>> } };

export type GetAnnouncementBannerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAnnouncementBannerQueryResult = { readonly allAnnouncement_banners: { readonly edges?: Maybe<ReadonlyArray<Maybe<{ readonly node: (
        Pick<AnnouncementBanner, 'title' | 'subtitle' | 'cta_text'>
        & { readonly cta_url?: Maybe<Pick<ExternalLink, 'url'>> }
      ) }>>> } };
